//  --------------------------------------------------------------------------------
//  _modal.scss
//  --------------------------------------------------------------------------------

//  Modal
//   ├─ Content
//   ├─ Header
//   ├─ Body
//   └─ Footer
//  Items
//   └─ Image





//  --------------------------------------------------------------------------------
//  Modal
//  --------------------------------------------------------------------------------

    .modal,
    .modal-backdrop {
        @include is-hidden;

        &.in {
            @include is-visible;
        }

    }

    .modal {
        @include fixed( top left bottom right );
        @include scrollbar( primary );
        overflow:               auto !important;
        z-index:                $z-index--modal + 1;
    }

    .modal-backdrop {
        @include fixed( top left bottom right );
        background-color:       hsb( 0, 0, 48, 0.75 );
        z-index:                $z-index--modal;
    }

    .modal-dialog {
        height:                 100%;
        margin:                 0 auto;
        max-width:              600px;
        transform:              none !important;//bso
    }


//  Content ------------------------------------------------------------------------

    .modal-content {
        background-color:       $color--primary--3;
        border:                 0;//bso
        border-radius:          0;//bso
        box-shadow:             none;//bso
        color:                  $text-color--primary;
        min-height:             100%;
        padding:                $span--m;
    }


//  Header -------------------------------------------------------------------------

    .modal-header {
        border-bottom:          1px solid $color--primary--4;
        // title + padding-bottom + border
        min-height:             35px + 10px + 1px;
        padding:                0;//bso
        padding-bottom:         $span--s;
        position:               relative;
    }

    // This is the x mark button in the top right corner
    // of the overlay used to dismiss the overlay.
    // Hide the button and use pseudo element to display
    // a custom x-mark.
    .modal-header .close {
        @include absolute( top right );
        @include qi;
        @extend %qi--xmark;
        color:                  white;
        opacity:                1;//bso
        text-shadow:            none;//bso
        visibility:             hidden;

        &:before {
            @include absolute( top right );
            @include size( 35px );
            line-height:            35px;
            transition:             background-color 0.2s ease-in-out;
            visibility:             visible;
        }

        &:hover::before {
            background-color:       $color--primary--2;
        }

    }

    // Heading of the modal, style as h2.
    .modal-title {
        @include size( 100%, 35px );
        font-size:              $font-size--subhead;
        font-weight:            $font-weight--normal;//bso
        line-height:            35px;
        overflow:               hidden;
        padding-right:          35px + $span--m;
        text-overflow:          ellipsis;
        white-space:            nowrap;
    }


//  Body ---------------------------------------------------------------------------

    .modal-body {
        padding:                $span--m 0;
    }

    .modal-body__section {
        @include clearfix;

        & + & {
            margin-top:             $span--l;
        }

    }


//  Footer -------------------------------------------------------------------------

    .modal-footer {
        border:                 0;//bso
        padding:                $span--m 0;
        text-align:             left;//bso
    }





//  --------------------------------------------------------------------------------
//  Items
//  --------------------------------------------------------------------------------

//  Image --------------------------------------------------------------------------

    .image--modal {
        background-color:       white;
        margin:                 0 (-$span--m);
        min-height:             300px;
    }

    .layer-preview {
        max-width:              350px;
        transform:              rotate(0deg);
        transition:             transform 0.25s ease-in-out;
    }