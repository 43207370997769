//  --------------------------------------------------------------------------------
//  _grid.scss
//  --------------------------------------------------------------------------------

//  Grid
//   └─ Sub sections
//  Columns
//   └─ Sizes





//  --------------------------------------------------------------------------------
//  Grid
//  --------------------------------------------------------------------------------

    .row,
    .row.content__section {
        @include clearfix;
        margin-left:            (-$span--m);
        margin-right:           (-$span--l) + (-$span--m);
    }

    .form-horizontal .form-group {
        margin-left:            0;
        margin-right:           (-$span--m);
    }





//  --------------------------------------------------------------------------------
//  Columns
//  --------------------------------------------------------------------------------

    .col-xs-,
    .col-md-,
    .col-lg- {

        &1,
        &2,
        &3,
        &4,
        &5,
        &6,
        &7,
        &8,
        &9,
        &10,
        &11,
        &12 {
            float:                  left;
            min-height:             1px;
            padding:                0;
            padding-right:          $span--l;
            position:               relative;
        }

    }

    .form-horizontal .form-group [class*='col-'] {
        padding-left:           0;
        padding-right:          $span--m;
    }