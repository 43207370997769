//  --------------------------------------------------------------------------------
//  _badge.scss
//  --------------------------------------------------------------------------------

//  Badge





//  --------------------------------------------------------------------------------
//  Badge
//  --------------------------------------------------------------------------------

    .badge {
        @include relative( top -1px );
        background-color:       hsb( 8, 70, 85 );
        border-radius:          $span--s;
        font-size:              $font-size--small;
        font-weight:            $font-weight--bold;
        height:                 17px;
        padding:                2px 8px;

        .list-group-item.active > & {
            background-color:       hsb( 8, 70, 85 ) !important;
            color:                  $text-color--primary !important;
        }

    }