//  --------------------------------------------------------------------------------
//  _navbar.scss
//  --------------------------------------------------------------------------------

//  Navbar
//   ├─ Application header
//   ├─ Brand
//   └─ Nav dropdown





//  --------------------------------------------------------------------------------
//  Navbar
//  --------------------------------------------------------------------------------

//  Application header -------------------------------------------------------------

    .navbar {
        background-color:       $color--secondary--1;
        border:                 0;//bso
        border-radius:          0;//bso
        color:                  $text-color--secondary;
        transition:             transform 0.2s ease-in-out,
                                width 0.2s ease-in-out;
        z-index:                $z-index--header;
    }


//  Brand --------------------------------------------------------------------------

    .navbar-brand {
        @include size( $panel-size, 50px );
        border:                 0;
        float:                  left;
        padding:                0;//bso

        @media ( max-width: 1000px ) {
            display:                flex;
            justify-content:        center;
            width:                  1000px * (2/12);
        }
    }

    .navbar-brand > img {
        height:                 auto;
        margin:                 auto 0;
        max-height:             50px;
        padding:                6px $span--m;
        max-width:              100%;
    }


//  Links / buttons ----------------------------------------------------------------

    .navbar-nav > li {
        float:                  left;

        > a,
        > button {
            border:                 0 !important;
            background-color:       $color--secondary--1 !important;
            color:                  $text-color--secondary !important;
            font-weight:            $font-weight--bold;
            padding:                0 $span--s;

            &,
            &::before {
                height:                 50px;
                line-height:            50px;
            }

            &.active,
            &:hover {
                background-color:       $color--secondary--2 !important;
            }

        }

        //  - (.navbar-nav > .dropdown.open > a)
        //  - (.navbar-nav > .dropdown.open > button)
        &.open > a,
        &.open > button {
            background-color:       $color--secondary--2 !important;
        }

    }

    .navbar-btn {
        margin:                 0;
        text-shadow:            none;

        > .qi {
            margin:                 0;
        }

        > .qi::before {
            line-height:            50px;
        }

        &:focus,
        &:hover {
            border:                 0;
        }

    }

    // Spacing between adjacent navbar navigation
    // groups and the inner nagivation items.
    .navbar-nav,
    .navbar-nav > li + li {
        margin-left:            1px !important;
    }


//  Text ---------------------------------------------------------------------------

    .navbar-text {
        color:                  $text-color--secondary;
        font-weight:            $font-weight--medium;
        height:                 50px;
        line-height:            50px;
        margin:                 0 $span--s;//bso
    }