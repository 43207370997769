//  --------------------------------------------------------------------------------
//  _typography.scss
//  --------------------------------------------------------------------------------

//  Font styles
//   ├─ Headings
//   ├─ Paragraphs
//   └─ Other
//  Webfonts
//   ├─ Import
//   └─ Qantifi iconset





//  --------------------------------------------------------------------------------
//  Font styles
//  --------------------------------------------------------------------------------

//  Headings -----------------------------------------------------------------------

    h1,
    .h1 {
        font-size:              $font-size--head;
        font-weight:            $font-weight--bold;
        line-height:            1.5em;
    }

    h2,
    .h2 {
        font-size:              $font-size--subhead;
        font-weight:            $font-weight--bold;
        line-height:            1.5em;
    }

    h3,
    .h3 {
        font-size:              $font-size--body;
        font-weight:            $font-weight--bold;
        line-height:            1.5em;
    }


//  Paragraphs ---------------------------------------------------------------------

    p,
    pre {
        line-height:            1.5em;

        & + & {
            margin-top:             1.5em;
        }

    }

    pre {
        border-radius:          0;
    }


//  Code ---------------------------------------------------------------------------

    code {
        background-color:       $color--secondary--1;
        border-radius:          0;
        color:                  $text-color--secondary;
        display:                inline-block;

        // Code blocks (pre > code).
        pre > & {
            background-color:       $color--secondary--1;
            padding:                $span--s;
            width:                  100%;
        }

    }


//  Other --------------------------------------------------------------------------

    em {
        @extend %text--italic;
    }

    strong {
        @extend %text--bold;
    }

    hr {
        border-bottom:          1px solid $color--secondary--1;

        .modal &,
        .sidebar--products &,
        .sidebar & {
            border-bottom:          1px solid $color--primary--4;
        }

    }





//  --------------------------------------------------------------------------------
//  Webfonts
//  --------------------------------------------------------------------------------

//  Import -------------------------------------------------------------------------

    @font-face {
        font-family:            'Qantifi Icons';
        font-style:             normal;
        font-weight:            normal;
        src:                    url( '../fonts/qantifi-icons.woff' ) format( 'woff' ),
                                url( '../fonts/qantifi-icons.ttf' ) format( 'truetype' );
    }

    @font-face {
        font-family:            'DIN';
        font-style:             normal;
        font-weight:            normal;
        src:                    url( '../fonts/din.woff2' ) format( 'woff2' ),
                                url( '../fonts/din.woff' ) format( 'woff' ),
                                url( '../fonts/din.ttf') format( 'truetype' );
    }


//  Qantifi iconset ----------------------------------------------------------------

//  use case:
//    class="qi qi--bell"

    @mixin qi {
        display:                inline;

        &::before {
            font:                   24px 'Qantifi Icons';
        }

    }

    .qi {
        @include qi;
    }

    // Using a map so that it is much easier to read
    // and maintain. Please note that to use an icon you
    // will need 'qi--' prepended onto the name.
    // examples:
    //   - .arrow--down--sml (nope, it won't exist)
    //   - .qi--arrow--down--sml (yep)
    $qantifi-iconset: (
        align--h--left:         '\e635',
        align--h--center:       '\e636',
        align--h--right:        '\e637',
        align--v--top:          '\e638',
        align--v--center:       '\e639',
        align--v--bottom:       '\e63a',
        angle:                  '\e60d',
        arrow--down--sml:       '\e64e',
        arrow--first:           '\e646',
        arrow--last:            '\e647',
        arrow--left:            '\e644',
        arrow--left--sml:       '\e64b',
        arrow--right:           '\e645',
        arrow--right--sml:      '\e64c',
        arrow--up--sml:         '\e64d',
        barcode:                '\e632',
        basket:                 '\e633',
        bell:                   '\e614',
        bold:                   '\e609',
        box:                    '\e61e',
        briefcase:              '\e61b',
        calendar:               '\e631',
        camera:                 '\e61c',
        certificate:            '\e620',
        chain--broken:          '\e627',
        chain--linked:          '\e626',
        checkmark:              '\e642',
        checkmark--sml:         '\e649',
        circle:                 '\e611',
        clipboard:              '\e624',
        clock:                  '\e61f',
        cog:                    '\e641',
        cog--sml:               '\e648',
        command-prompt:         '\e619',
        copy:                   '\e625',
        cursor:                 '\e600',
        distribute--h--left:    '\e63b',
        distribute--h--center:  '\e63c',
        distribute--h--right:   '\e63d',
        distribute--v--top:     '\e63e',
        distribute--v--center:  '\e63f',
        distribute--v--bottom:  '\e640',
        eye--open--sml:         '\e653',
        eye--shut--sml:         '\e654',
        filter:                 '\e634',
        flip--h:                '\e60b',
        flip--v:                '\e60c',
        folder:                 '\e628',
        grid:                   '\e62a',
        hand:                   '\e601',
        italic:                 '\e60a',
        line:                   '\e606',
        lock--open--sml:        '\e651',
        lock--shut--sml:        '\e652',
        magnify-glass:          '\e603',
        menu:                   '\e630',
        minus--sml:             '\e650',
        panel--bottom:          '\e62c',
        panel--full:            '\e62b',
        panel--right:           '\e62d',
        paper:                  '\e629',
        pencil:                 '\e613',
        people--group:          '\e616',
        people--man:            '\e617',
        people--woman:          '\e618',
        plus--sml:              '\e64f',
        program-window:         '\e621',
        replace:                '\e607',
        rotate--ccw:            '\e60e',
        rotate--cw:             '\e60f',
        ruler:                  '\e604',
        scissors:               '\e623',
        spline:                 '\e605',
        square:                 '\e610',
        stamp:                  '\e602',
        tag:                    '\e61a',
        text:                   '\e608',
        trash:                  '\e622',
        triangle:               '\e612',
        upload:                 '\e615',
        wax-seal:               '\e61d',
        window--expand:         '\e62e',
        window--shrink:         '\e62f',
        xmark:                  '\e643',
        xmark--sml:             '\e64a'
    );

    @each $name, $icon in $qantifi-iconset {

        .qi--#{$name}::before,
        %qi--#{$name}::before {
            content:                $icon;
        }

    }