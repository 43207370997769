//  --------------------------------------------------------------------------------
//  _image.scss
//  --------------------------------------------------------------------------------

//  Image container
//   ├─ Content
//   └─ Table thumbnail
//  Image carousel
//   ├─ Images
//   ├─ Controls
//   └─ Indicators





//  --------------------------------------------------------------------------------
//  Image container
//  --------------------------------------------------------------------------------

//  Simple image container
//
//  Contains and centers an image element within the
//  boundaries of its parent container (.image).

//  Use case:
//      <div class="image">
//          <img> [or] <svg>
//      </div>

    .image {
        // Force whatever element this class may be
        // in to always display as a block.
        display:                block;
        position:               relative;
        height:                 100%;

        img,
        svg {
            @include absolute( top left bottom right );
            display:                block;
            margin:                 auto;
            max-height:             100%;
            max-width:              100%;
        }

    }


//  Content ------------------------------------------------------------------------

    .image--content {
        background-color:       white;
        border:                 1px solid $color--secondary--1;
        height:                 $panel-size;
        position:               relative;

        img {
            padding:                $span--s;
        }

    }


//  Table thumbnail ----------------------------------------------------------------

    .image--table {
        @include size( 40px );
        background-color:       white;
        border:                 1px solid $color--secondary--1;
        margin-top:             -$span--s;
        margin-bottom:          -$span--s;

        img {
            padding:                $span--xs;
        }

    }


//  Design layer thumbnails --------------------------------------------------------

    .image-layer-thumbnail {
        background-color:       white;
        border:                 3px solid $color--primary--4;
        margin-bottom:          $span--l;
        padding-bottom:         66.66%;
        transition:             border 0.25s ease-in-out,
                                opacity 0.25s ease-in-out;

        &.active {
            border-color:           $color--primary--1;
        }

        &.inactive {
            opacity:                0.5;
        }
    }

    .image-layer-thumbnail > img {
        @include size( auto, 100% );
        padding:                $span--s;
    }





//  --------------------------------------------------------------------------------
//  Image carousel
//  --------------------------------------------------------------------------------

    .carousel {
        background-color:       white;
        position:               relative;

        .modal & {
            margin:                 0 (-$span--l);
        }

    }


//  Images -------------------------------------------------------------------------

    // By default, all images in the slider is not
    // displayed except for the currently active image.
    .carousel-inner > .item {
        // Setting no height would be the simplest way to achieve
        // the hidden image initially. Then remove the no height
        // when the image is active height to allow the image
        // to be visible.
        height:                 0;
        margin:                 auto;
        max-width:              100%;
        transition:             opacity 0.25s ease-in-out;

        &.active {
            height:                 auto;
        }

        img {
            margin:                 0 auto;
        }

        // Lighten slider images when the slider is hovered over.
        // Instead of placing a slightly transparent white overlay
        // over the slider, instead make image slightly transparent.
        // As the slider has a white background it'll accomplish the
        // same effect.
        .carousel:hover > & {
            opacity:                0.75;
        }

    }


//  Controls -----------------------------------------------------------------------

    .carousel-control {
        @include size( 30%, 50% );
        opacity:                0;
        position:               absolute;
        z-index:                1;

        &:hover {
            opacity:                0;
        }

        &.right {
            cursor:                 url(../img/cursors/arrow--right.svg) 12 12, e-resize;
            right:                  0
        }

        &.left {
            cursor:                 url(../img/cursors/arrow--left.svg) 12 12, w-resize;
            left:                   0;
        }

    }


//  Indicators ---------------------------------------------------------------------

    // Container
    //
    // Display the image carousel indicators only when
    // hovered over the carousel.
    .carousel-indicators {
        opacity:                0;
        transition:             opacity 0.2s ease-in-out;

        .carousel:hover & {
            opacity:                1;
        }

    }

    //
    .carousel-indicators > li {
        background-color:       white;
        background-position:    center;
        background-repeat:      no-repeat;
        background-size:        contain;
        border:                 1px solid $color--secondary--3;
        border-radius:          0;
        height:                 35px !important;
        margin:                 0;
        position:               relative;
        width:                  35px !important;

        + li {
            margin-left:            $span--xs !important;
        }

        &::before {
            @include absolute( top left );
            @include size( 100% );
            content:                '';
            background-color:       hsb( 0, 0, 0, 0 );
            transition:             background-color 0.2s ease-in-out;
        }

        &.active::before {
            background-color:       hsb( 0, 0, 0, 0.1 );
        }

    }