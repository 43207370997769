//  --------------------------------------------------------------------------------
//  _project.scss
//  --------------------------------------------------------------------------------

//  Calendar sidebar setup
//   ├─ Toggle view button
//   ├─ Simple calendar section
//   └─ Detailed calendar section
//  Mini calendar
//  Main calendar





//  --------------------------------------------------------------------------------
//  Calender sidebar setup
//  --------------------------------------------------------------------------------

//  Toggle view button -------------------------------------------------------------

    .sidebar--calendar--toggle-view,
    .sidebar--calendar--config-view,
    .sidebar--calendar--add-slot,
    .sidebar--calendar--timezone {
        background-color:       $color--primary--2;
        color:                  $text-color--primary !important;

        &:focus,
        &:hover {
            background-color:       $color--primary--1;
            border:                 0 !important;
        }

    }


//  Simple calendar section --------------------------------------------------------

    .sidebar--calendar--simple {
        float:                  left;
        height:                 100%;
        overflow:               auto;
        padding:                $span--s $span--m;
        position:               relative;
        width:                  $panel-size;

        @media ( max-width: 1000px ) {
            padding:                $span--s;
            width:                  1000px * (2/12);
        }

    }


//  Detailed calendar section ------------------------------------------------------

    .sidebar--calendar--detail {
        @include absolute( right );
        border-left:            1px solid $color--primary--4;
        height:                 100%;
        padding:                $span--s $span--m;
        width:                  calc( 100% - 250px );

        @media ( max-width: 1000px ) {
            width:                  calc( 100% - #{ 1000px * (2/12) } );
        }

    }





//  --------------------------------------------------------------------------------
//  Mini calendar
//  --------------------------------------------------------------------------------

//  Calendar panel -----------------------------------------------------------------

    .sidebar--calendar--simple {

        .mini-calendar {
            font-family:            Helvetica Neue, Arial, sans-serif;
            margin-top:             30px;
            width:                  100%;
        }

        .number a {
            display:                none;
        }

    }

    // Border below the mini calendar controls.
    .sidebar--calendar--simple::after {
        @include absolute( top 55px left 15px );
        border-bottom:          1px solid $color--primary--4;
        content:                '';
        display:                block;
        width:                  calc( 100% - 30px );
    }

    .day {
        cursor:                 pointer;

        &.event {
            background-color:       $color--primary--2;

            &:hover {
                background-color:       $color--primary--1;
            }

        }

        &.last-month,
        &.next-month {
            @include is-hidden;
        }

    }





//  --------------------------------------------------------------------------------
//  Main calendar
//  --------------------------------------------------------------------------------

//  General styles -----------------------------------------------------------------

    .fc-time-grid-container {
        // Fix for fullcalendar stupidly setting a 0 height on
        // small screen sizes.
        height: auto !important;
    }

    // prevent all-day events from taking up too much vertical space
    .fc-agendaDay-view,
    .fc-agendaWeek-view,
    .fc-resourceAgendaDay-view {

        &.fc-agenda-view .fc-day-grid > .fc-row {
            @include scrollbar( secondary );

            height: 100px;
            overflow-x: hidden;
            overflow-y: auto;
        }

        &.fc-agenda-view .fc-day-grid > .fc-row .fc-content {
            height: auto;
        }

    }

    .fc-scroller {
        @include scrollbar( secondary );

        // Adjust height of calendar containers to
        // make the most of the available height
        .fc-resourceBasicDay-view.fc-basic-view &,
        .fc-basicDay-view.fc-basic-view &,
        .fc-basicWeek-view.fc-basic-view &,
        .fc-month-view.fc-basic-view & {
            height:                 100% !important;
        }

        .fc-resourceAgendaDay-view.fc-agenda-view &,
        .fc-agendaDay-view.fc-agenda-view &,
        .fc-agendaWeek-view.fc-agenda-view & {
            height:                 calc( 100% - 105px ) !important;
        }

        .sidebar--calendar & {
            @include scrollbar( secondary );
        }

    }

    .calendar {
        height:                 100%;
    }

    .fc-view-container {
        color:                  $text-color--secondary;
        background-color:       white;
        height:                 calc( 100% - 55px );
    }

    .fc-view,
    .fc-view > table {
        height:                 100%;
    }

    //height of table cells
    .fc-slats td {
        height:                 35px;
    }

    .fc-unthemed {

        hr,
        tbody,
        td,
        th,
        thead,
        .fc-popover,
        .fc-row {
            border-color:           $color--secondary--1;

            .sidebar--calendar & {
                border-color:           $color--secondary--2;
            }

        }

    }


    .fc-basic-view .fc-day-grid {
        height:                 100%;
    }

    .fc-basicWeek-view.fc-basic-view .fc-row.fc-week.fc-widget-content {
        height:                 auto !important;
        min-height:             100%;
    }

    // Current day
    .fc-today {
        background-color:       $color--secondary--1;

        .sidebar--calendar & {
            background-color:       $color--secondary--1;
        }

    }

    .fc-day-number {
        text-align:             left;
    }

    .fc th {
        text-align:             left;
    }

    .fc-toolbar {
        margin-bottom:          $span--m;
    }

    .fc hr {
        background-color:       $color--secondary--1;
        padding:                0;
        height:                 3px;

        .sidebar & {
            background-color:       white;
            border-top:             1px solid $color--secondary--2;
        }

    }

    th.fc-widget-header {
        // border:                 none;
        font-weight:            $font-weight--bold;
        padding:                $span--xs;
    }

    //main calendar heading
    .calendar__heading {
        float:                  left;
        padding-left:           $span--m;
        padding-top:            $span--xs;
    }


    // month view
    .fc-month-view {

        .fc-widget-content {
            position:               relative;
        }

        .fc-day-grid-container {
            @include scrollbar( primary );
            height:                 100%;
            position:               absolute;
            overflow-x:             auto;
        }

        .fc-day-grid {
            height:                 100%;
        }

        tbody .fc-row {
            // ~1/6
            min-height:             16.66%;
        }

    }

    // calendar event labels
    .fc-event {
        @extend .label;//@@@@@
        border-width:           1px;
        color:                  white;
        display:                block;
        margin:                 3px 3px 0 3px;

        .fc-bg {
            opacity:                0.1;
        }

    }
