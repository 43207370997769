//  --------------------------------------------------------------------------------
//  _tooltip.scss
//  --------------------------------------------------------------------------------

//  Tooltip





//  --------------------------------------------------------------------------------
//  Tooltip
//  --------------------------------------------------------------------------------

    .tooltip {
        margin-top:             0 !important;//bso
        opacity:                0;
        padding:                0 !important;//bso
        transition:             opacity 0.2s ease-in-out;
        z-index:                $z-index--tooltip;

        &.in {
            opacity:                1;
            // Delay for three quarters of a second
            // before displaying the tooltip.
            transition-delay:       0.75s;
        }

    }

    .tooltip-inner {
        background-color:       #333;
        border-radius:          0;//bso
        font-size:              $font-size--small;
        font-weight:            $font-weight--medium;
    }

    .tooltip-arrow {
        display:                none !important;//bso
    }