//  --------------------------------------------------------------------------------
//  _animation.scss
//  --------------------------------------------------------------------------------

//  Opacity
//   ├─ Fade in and out
//   ├─ Fade in
//   └─ Fade out





//  --------------------------------------------------------------------------------
//  Opacity
//  --------------------------------------------------------------------------------

//  Fade in and out ----------------------------------------------------------------

    @keyframes fade-in-out {

        10%, 90% {
            opacity:                1
        }

        45%, 55% {
            opacity:                0.2;
        }

    }

    @mixin fade-in-out( $duration:2s, $iteration-count:1 ) {
        animation-duration:     $duration;
        animation-iteration-count: $iteration-count;
        animation-name:         fade-in-out;
        animation-timing-function: ease-in-out;
    }


//  Fade in ------------------------------------------------------------------------

    @keyframes fade-in {

        from {
            opacity:                0;
        }

        to {
            opacity:                1;
        }

    }

    @mixin fade-in( $duration:0.2s ) {
        animation-duration:     $duration;
        animation-name:         fade-in;
        animation-timing-function: ease-in-out;
    }

//  Fade out -----------------------------------------------------------------------

    @keyframes fade-out {

        from {
            opacity:                1;
        }

        to {
            opacity:                0;
        }

    }

    @mixin fade-out( $duration:0.2s ) {
        animation-duration:     $duration;
        animation-name:         fade-out;
        animation-timing-function: ease-in-out;
    }