//  --------------------------------------------------------------------------------
//  _print.scss
//  --------------------------------------------------------------------------------

//  Setup
//   ├─ Parameters
//   ├─ Normalise / reset
//   ├─ Typography
//   └─ Grid
//  Components
//   ├─ Table
//   ├─ Inputs
//   ├─ Plan canvas
//   └─ Cards
//  Sections
//   ├─ Header
//   └─ Footer





//  --------------------------------------------------------------------------------
//  Setup
//  --------------------------------------------------------------------------------

//  Parameters ---------------------------------------------------------------------

//  Printout font sizes

    $print-font-size--fine:     5pt;
    $print-font-size--body:     8pt;
    $print-font-size--subhead:  12pt;
    $print-font-size--head:     18pt;

//  Printout margins

    // Note: page margin should be 10mm, the remaining
    // 2.5mm will be added on the page container
    $print-margin--page:        7.5mm;
    $print-margin--gutter:      2.5mm;


//  Normalise / reset --------------------------------------------------------------

    @page {
        margin:                 0;
    }

    // When not printing, hide print container.
    // Not using 'display:none' as it has weird
    // issues with angular directives.
    .print {
        height:                 0;
        opacity:                0;
        position:               relative;
        visibility:             hidden;
    }

    .page {
        @include size( 100% );
        page-break-after:       always;
        page-break-inside:      avoid;
        overflow:               hidden;
        padding:                $print-margin--page;
        position:               relative;
    }

    .page__content {
        border:                 $print-margin--gutter solid transparent;
        height:                 100%;
        width:                  100%;
        position:               relative;

        // Add border around design printouts.
        .print--design &::after {
            @include absolute( top -2.5mm left -2.5mm );
            border:                 1pt solid $color--secondary--1;
            content:                '';
            display:                block;
            height:                 calc( 100% + #{$print-margin--gutter*2} );
            width:                  calc( 100% + #{$print-margin--gutter*2} );
        }

    }

    @media print {

        body {
            max-height:             none;
            max-width:              none;
            min-height:             0;
            min-width:              0;
        }

        // When printing, hide:
        .alert,
        .content__section,
        .navbar,
        .print--hide,
        .sidebar,
        .table {
            display:                none;
        }

        // When printing, show:
        .print {
            opacity:                1;
            visibility:             visible;
        }

    }

    .print-mode {

        // When faux printing, hide:
        .alert,
        .content__section,
        .navbar,
        .print--hide,
        .sidebar,
        .table {
            display:                none;
        }

        // When faux printing, show:
        .print {
            opacity:                1;
            visibility:             visible;
        }

    }

    @media print {

        // Making sure that any parent containers fill
        // a entire pront page (100% height and width).
        // Reset any overflows back to the default.
        body,
        .content,
        .content__container--x,
        .content__container--y,
        .print,
        .view,
        [ui-view='main'] {
            @include size( 100% );
            border:                 0;
            max-height:             100% !important;
            max-width:              100% !important;
            overflow:               visible;
            margin:                 0 !important;
            padding:                0;
            transform:              none !important;
        }

        body {
            position:               static;
        }

        //
        .print,
        .view {
            @include absolute( top left );
        }

    }

    // Faux print
    .print-mode {

        body,
        .content,
        .content__container--x,
        .content__container--y,
        .view {
            @include size( 100% );
            border:                 0;
            max-height:             100% !important;
            max-width:              100% !important;
            overflow:               visible;
            padding:                0;
            transform:              none !important;
        }

    }


//  Sizes --------------------------------------------------------------------------

    // A2 landscape
    .print--a2--landscape {
        @include size( 594mm, 420mm );
    }
    // A2 portrait
    .print--a2--portrait {
        @include size( 420mm, 594mm );
    }

    // A3 landscape
    .print--a3--landscape {
        @include size( 420mm, 297mm );
    }

    // A3 portrait
    .print--a3--portrait {
        @include size( 297mm, 420mm );
    }

    // A4 landscape
    .print--a4--landscape {
        @include size( 297mm, 210mm );
    }

    // A4 portrait
    .print--a4--portrait {
        @include size( 210mm, 297mm );
    }


//  Typography ---------------------------------------------------------------------

    @media print {

        body {
            color:                  #222;
            font-family:            'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
            font-size:              $print-font-size--body;
            font-weight:            300;
        }

    }

    .guidelines,
    .print {

        // As a precaution: disable any font rendering
        * {
            -webkit-font-smoothing: auto;
            -moz-osx-font-smoothing:auto;
        }

        h1, .h1 {
            font-size:              $print-font-size--head;
            height:                 24pt;
            margin:                 0;
        }

        h2, .h2 {
            font-size:              $print-font-size--subhead;
            font-weight:            500;
            height:                 12pt;
            line-height:            12pt;
            margin:                 0;
            transform:              translateY( 1pt );
        }

        h3, .h3 {
            font-size:              inherit;
            font-weight:            500;
            line-height:            inherit;
            margin:                 0;
        }

        p, .p {
            border-top:             2pt solid transparent;
            line-height:            12pt;
            margin:                 0;
            transform:              translateY( 2pt );
        }

        .text--small {
            font-size:              $print-font-size--fine;
            line-height:            6pt;

            h2 + &,
            .h2 + & {
                border-top:             6pt sold transparent;
            }

        }

    }


//  Grid ---------------------------------------------------------------------------

    .grid {
        @include clearfix;
        min-width:              calc( 100% + #{$print-margin--gutter} );
    }

    .grid--table {
        display:                table;
    }

    .column--1,
    .column--2,
    .column--3,
    .column--4,
    .column--5,
    .column--6,
    .column--7,
    .column--8,
    .column--9,
    .column--10 {
        float:                  left;
        min-height:             1pt;
        padding-right:          $print-margin--gutter;
        word-wrap:              break-word;

        .grid--table > & {
            float:                  none;
            display:                table-cell;
        }

        .is-bottom-aligned > & {
            vertical-align:         bottom;
        }

    }

    .column--1  { width: 10%; }
    .column--2  { width: 20%; }
    .column--3  { width: 30%; }
    .column--4  { width: 40%; }
    .column--5  { width: 50%; }
    .column--6  { width: 60%; }
    .column--7  { width: 70%; }
    .column--8  { width: 80%; }
    .column--9  { width: 90%; }
    .column--10 { width: 100%; }





//  --------------------------------------------------------------------------------
//  Components
//  --------------------------------------------------------------------------------

//  Table --------------------------------------------------------------------------

    .print {

        table {
            width:                  100%;
        }

        tbody tr {
            border-bottom:          1px solid $color--secondary--1;
        }

        td {
            padding-right:          $print-margin--gutter;
        }

        tfoot {
            border-top:             12pt solid transparent;
        }

        th {
            font-weight:            500;
        }

        td,
        th {
            float:                  none !important;
            height:                 24pt;
            line-height:            12pt;
            transform:              translateY( 3pt );
            vertical-align:         bottom;
        }

        table h3 + h2 {
            border-top:             9pt solid transparent;
        }

    }


//  Inputs -------------------------------------------------------------------------

    hr {
        border:                 0;
        border-bottom:          1px solid $color--secondary--1;
        margin:                 0;
    }

    .form-field {
        border-bottom:          1px solid $color--secondary--1;
        height:                 24pt !important;
        margin-bottom:          -1px;
        position:               relative;

        > p {
            transform:              translateY( 12pt ) !important;
        }

    }

    .form-field--date {

        &::after,
        &::before {
            @include absolute( bottom 3pt );
            border-right:           1px solid $color--secondary--1;
            content:                '';
            height:                 12pt;
        }

        &::after {
            left:                   37.5%;
        }

        &::before {
            right:                  37.5%;
        }

    }

    //
    .form-field--signature > img {
        @include absolute( top -6pt left 48pt );
        max-height:             36pt;
        max-width:              calc( 100% - 48pt );
    }


//  Plan canvas --------------------------------------------------------------------

    // (img.plan)
    .plan {
        @include absolute( top left bottom right );
        display:                block;
        margin:                 auto;
        max-height:             100%;
        max-width:              100%;
        z-index:                -1;
    }

    // Canvas in design module.
    // Getting it prepared for export via nightmare.
    .print-mode .canvas {
        @include size( 100% );
        position:               relative;
    }

    // (print-mode .canvas > .layer)
    .print-mode .layer {
        @include absolute( top bottom right left );
        margin:                 auto;
    }

        // Removes the border around the canvas
        .print-mode .grid > rect {
            stroke: none !important;
        }

        @media print {
            .grid > rect {
                stroke: none !important;
            }
        }

        //
        .print-mode .layer > g {
            transform: scale(1);
        }


//  Cards --------------------------------------------------------------------------

    .card {
        .print--a2--landscape & {
            width:                  calc( 12.5% - 6pt );
        }
        .print--a2--portrait &,
        .print--a3--landscape & {
            width:                  calc( 16.666% - 6pt );
        }
        .print--a3--portrait &,
        .print--a4--landscape & {
            width:                  calc( 20% - 6pt );
        }
        .print--a4--portrait & {
            width:                  calc( 25% - 6pt );
        }
	}

	@media print {
		.card {
			border:                 1px solid $color--secondary--1;
			float:                  left;
			height:                 106pt;
			margin-bottom:          6pt;
			margin-left:            6pt;
			position:               relative;
		}
	}

	.print {
		.card {
			border:                 1px solid $color--secondary--1;
			float:                  left;
			height:                 106pt;
			margin-bottom:          6pt;
			margin-left:            6pt;
			position:               relative;
		}
	}


    .card__image {
        height:                 52pt;
        margin:                 6pt;
        width:                  calc( 100% - 54pt );
    }

    .card__attributes {
        @include absolute( top right );
        margin:                 2pt;
        text-align:             center;
        width:                  36pt;

        > .image,
        > .text--small {
            border:                 1px solid $color--secondary--1;
            display:                block;
            margin-bottom:          0 !important;
            margin-top:             0 !important;
        }

        > .text--small {
            border-top:             none;
            height:                 12pt;
            line-height:            12pt;
        }

        > .image {
            height:                 36pt;
        }

    }

    .card__title {
        height:                 38pt;
        line-height:            12pt;
        padding:                2pt 4pt;
        overflow:               hidden;
    }





//  --------------------------------------------------------------------------------
//  Sections
//  --------------------------------------------------------------------------------

//  Header -------------------------------------------------------------------------

    .page__header {
        max-height:             108pt;
        margin-bottom:          12pt;
        padding-bottom:         12pt;
        width:                  100%;
    }

    .brand-logo {
        @include size( auto, 21pt );
        display:                inline-block;
        margin-bottom:          3pt;
    }


//  Footer -------------------------------------------------------------------------

    .page__footer {
        @include absolute( bottom left );
        border-top:             1px solid $color--secondary--1;
        padding-top:            6pt;
        width:                  100%;
    }

    .page__footer__comments,
    .page__footer__form {
        float:                  left;
        min-height:             1pt;
        padding-right:          $print-margin--gutter;
        word-wrap:              break-word;
    }

    .page__footer__comments {

        .print--a2--landscape & {
            width:                  83.33%;
        }
        .print--a2--portrait &,
        .print--a3--landscape & {
            width:                  80%;
        }
        .print--a3--portrait &,
        .print--a4--landscape & {
            width:                  75%;
        }
        .print--a4--portrait & {
            width:                  66.66%;
        }
    }

    .page__footer__form {
        .print--a2--landscape & {
            width:                  16.66%;
        }
        .print--a2--portrait &,
        .print--a3--landscape & {
            width:                  20%;
        }
        .print--a3--portrait &,
        .print--a4--landscape & {
            width:                  25%;
        }
        .print--a4--portrait & {
            width:                  33.33%;
        }
    }

    .page__footer__comments > p:last-child {
        column-gap:             2.5mm;

        .print--a2--landscape & {
            column-count:           5;
        }
        .print--a2--portrait &,
        .print--a3--landscape & {
            column-count:           4;
        }
        .print--a3--portrait &,
        .print--a4--landscape & {
            column-count:           3;
        }
        .print--a4--portrait & {
            column-count:           2;
        }
    }
