//  --------------------------------------------------------------------------------
//  _reset.scss
//  --------------------------------------------------------------------------------





//  --------------------------------------------------------------------------------
//  Reset and normalise styles
//  --------------------------------------------------------------------------------

    html, body, div, span, object, iframe,
    header, footer, aside, section, article,
    address, legend, code, pre,
    ul, ol, li, dl, dt, dd,
    h1, h2, h3, h4, h5, h6, p, a, i,
    strong, em, sub, sup,
    fieldset, radiogroup, figcaption, figure,
    form, input, select, textarea, label, button,
    table, thead, tfoot, tbody, tr, th, td,
    img, audio, video {
        background-color:       transparent;
        border:                 0;
        box-sizing:             border-box;
        margin:                 0;
        outline:                0;
        padding:                0;
    }

    // Making sure that older browsers
    // display html5 elements correctly.
    article, aside, details, fieldset, figcaption,
    figure, footer, header, hgroup, menu,
    nav, radiogroup, section {
        display:                block;
    }

    table {
        border-collapse:        collapse;
        border-spacing:         0;
    }

    img {
        display:                block;
    }

    body, html {
        @include size( 100vw, 100vh );
        position:               relative;
    }


//  Typography ---------------------------------------------------------------------

    // The following elements can have text and
    // therefore should get typograhic styling.
    h1, h2, h3, p, a, i,
    button, li, th, td,
    div, span, section, pre,
    label, input, textarea {
        // Allows for font styles (color, typeface, size
        // and weight) to be later inherited by styles on
        // the specific element or parent.
        color:                  inherit;
        font-family:            inherit;
        font-size:              inherit;
        font-weight:            inherit;
        // Fix sloppy rendering of font and icons
        // on chrome (mac) and firefox (mac).
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing:grayscale;
    }

    body {
        font-family:            'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
        font-size:              $font-size--body;
        font-weight:            $font-weight--normal;
    }


//  Interactive elements -----------------------------------------------------------

    a,
    button,
    [data-dismiss],
    [data-toggle],
    [ng-click] {
        cursor:                 pointer;
    }

    a {
        border-bottom:          1px solid $color--secondary--2;
        padding-bottom:         0.1em;
        transition:             border-color 0.2s ease-in-out;

        &,
        &:hover,
        &:focus {
            color:                  inherit;
            outline:                0;
            text-decoration:        none;
        }

        &:hover,
        &:focus {
            border-bottom-color:    $text-color--placeholder;
        }

    }

    button::-moz-focus-inner {
        border:                 0;
        padding:                0;
    }


//  Forms --------------------------------------------------------------------------

    // 'Remove' pale yellow autofill background
    // which chrome will set on autocompleted fields
    input:-webkit-autofill {
        // Works by delaying the display of the background color.
        // Fun fact: a user would need to wait nearly 3 hours to
        // ever see a yellow input field take effect.
        transition:             background-color 9999s step-end !important;
    }