//  --------------------------------------------------------------------------------
//  _tool-palette.scss
//  --------------------------------------------------------------------------------

//  Tool palette





//  --------------------------------------------------------------------------------
//  Tool palette
//  --------------------------------------------------------------------------------

    // Container
    //
    // On the left and bottom edges - hide the borders.
    .btn-group--tool-palette {
        margin-bottom:          -1px;
        margin-left:            -1px;
        position:               relative;

        // Visual seperators
        > .btn {
            border:                 0 solid $color--primary--4;
            border-bottom-width:    1px;
            border-left-width:      1px;
            z-index:                0;
        }

        // Covers the border along the left edge of the
        // tool palatte container.
        &::after {
            @include absolute( top left );
            @include size( 1px, 100% );
            background-color:       $color--primary--3;
            content:                '';
            display:                block;
            z-index:                1;
        }

        // Covers the border along the bottom edge
        // of the tool palatte container.
        &::before {
            @include absolute( bottom left );
            @include size( 100%, 1px );
            background-color:       $color--primary--3;
            content:                '';
            display:                block;
            z-index:                1;
        }

    }