//  --------------------------------------------------------------------------------
//  _panel.scss
//  --------------------------------------------------------------------------------

//  Panel
//   ├─ Group
//   ├─ Container
//   ├─ Heading
//   └─ Body





//  --------------------------------------------------------------------------------
//  Panel
//  --------------------------------------------------------------------------------

//  Group --------------------------------------------------------------------------

    .panel-group {
        margin:                 0;//bso
    }


//  Container ----------------------------------------------------------------------

    .panel {
        @include scrollbar( primary );
        background-color:       $color--primary--3;
        border:                 0;//bso
        border-radius:          0 !important;//bso
        box-shadow:             none !important;//bso
        color:                  $text-color--primary;
        margin:                 0 !important;//bso
        min-height:             35px;
        position:               relative;

        + .panel {
            border-top:             1px solid $color--primary--2;
        }

    }


//  Heading ------------------------------------------------------------------------

//  Panel headings may or may not have a child title
//  element. Headings can be setup as:
//
//  1.
//  <div class="panel-heading">
//      <h3 class="panel-title"></h3>
//  </div>
//
//  2.
//  <h3 class="panel-heading"></h3>
//  However without a panel-title child element there
//  will not be an arrow indicator for that
//  collapsable panel.

    .panel-heading,
    .panel-title {
        @include size( 100%, 35px );
        background-color:       $color--primary--1 !important;
        border:                 0 !important;
        border-radius:          0 !important;//bso
        color:                  $text-color--primary !important;
        display:                block;
        font-size:              $font-size--body;
        font-weight:            $font-weight--bold;
        line-height:            35px;
        padding:                0 $span--m;
    }

    .panel-title {
        @include absolute( top left );

        // If the panel title has a toggle to collapse its
        // panel body, then display an arrow to indicate this.
        &[data-toggle='collapse']::after {
            @include relative( top 3px );
            @include size( 0 );
            border-left:            4px solid transparent;
            border-right:           4px solid transparent;
            border-top:             4px solid $color--primary--4;
            content:                '';
            display:                inline-block;
            margin:                 5px;
            transition:             transform 0.2s ease-in-out;
        }

        // When panel has been collapsed, rotate the
        // arrow so it points to the right.
        &.collapsed::after {
            transform:              rotate( -90deg );
        }

    }

    .panel-heading > .btn {
        background-color:       transparent !important;
    }


//  Body ---------------------------------------------------------------------------

    .panel-body {
        border:                 0 !important;//bso
        padding:                $span--s $span--m;
    }