//  --------------------------------------------------------------------------------
//  _utility.scss
//  --------------------------------------------------------------------------------

//  About this file
//  States
//   ├─ Hidden
//   └─ Visible
//  Effects
//  Floats
//   ├─ clearfix
//   ├─ Float left
//   └─ Float right
//  Text
//   ├─ Align left
//   ├─ Align center
//   ├─ Align right
//   ├─ Preformat
//   ├─ Bold
//   └─ Italic
//  Custom scrollbar





//  --------------------------------------------------------------------------------
//  About this file
//  --------------------------------------------------------------------------------

//  This file is laid out so that the rule set/group is contained
//  in a mixin. This mixin will then be used to create a class
//  and placeholder class

//  Ways to incorporate a utility class into the project are:
//  - @include class;
//  - @extend %class;
//  - Directly using the class on a html element
//    (<element class="class"></element>)





//  --------------------------------------------------------------------------------
//  States
//  --------------------------------------------------------------------------------

//  Hidden -------------------------------------------------------------------------

    @mixin is-hidden {
        opacity:                0;
        transition:             opacity 0.2s ease-in-out,
                                visibility 0s step-end 0.2s;
        visibility:             hidden;
    }

    .is-hidden,
    %is-hidden {
        @include is-hidden;
    }


//  Visible ------------------------------------------------------------------------

    @mixin is-visible {
        opacity:                1;
        transition:             opacity 0.2s ease-in-out,
                                visibility 0s step-end;
        visibility:             visible;
    }

    .is-visible,
    %is-visible {
        @include is-visible;
    }





//  --------------------------------------------------------------------------------
//  Effects
//  --------------------------------------------------------------------------------





//  --------------------------------------------------------------------------------
//  Floats
//  --------------------------------------------------------------------------------

//  clearfix -----------------------------------------------------------------------

    @mixin clearfix {

        &::after {
            content:                '';
            display:                table;
            clear:                  both;
        }

    }

    .clearfix,
    %clearfix {
        @include clearfix;
    }


//  Float left ---------------------------------------------------------------------

    @mixin float--left {
        float:                  left !important;
    }

    .float--left,
    %float--left {
        @include float--left;
    }


//  Float right --------------------------------------------------------------------

    @mixin float--right {
        float:                  right !important;
    }

    .float--right,
    %float--right {
        @include float--right;
    }





//  --------------------------------------------------------------------------------
//  Text
//  --------------------------------------------------------------------------------

//  Align left ---------------------------------------------------------------------

    @mixin align--left {
        text-align:             left !important;
    }

    .align--left,
    %align--left {
        @include align--left;
    }


//  Align center -------------------------------------------------------------------

    @mixin align--center {
        text-align:             center !important;
    }

    .align--center,
    %align--center {
        @include align--center;
    }


//  Algin right --------------------------------------------------------------------

    @mixin align--right {
        text-align:             right !important;
    }

    .align--right,
    %align--right {
        @include align--right;
    }


//  Preformat ----------------------------------------------------------------------

    @mixin pre-format {
        white-space:            pre-wrap !important;
    }

    .pre-format,
    %pre-format {
        @include pre-format;
    }


//  Bold ---------------------------------------------------------------------------

    @mixin text--bold {
        font-weight:            $font-weight--bold !important;
    }

    .text--bold,
    %text--bold {
        @include text--bold;
    }


//  Italic ---------------------------------------------------------------------------

    @mixin text--italic {
        font-style:             italic !important;
    }

    .text--italic,
    %text--italic {
        @include text--italic;
    }





//  --------------------------------------------------------------------------------
//  Custom scrollbar
//  --------------------------------------------------------------------------------

//  Currently only webkit browsers have the option
//  to customize the scrollbar styles with css

    @mixin scrollbar( $type ) {

        // Scrollbar for panels, overlays and other elements
        // with a high contrast background
        @if $type == primary {

            &::-webkit-scrollbar {
                @include size( $span--xs );
                background-color:       $color--primary--3;
            }

            &::-webkit-scrollbar-thumb {
                background-color:       $color--primary--1;
            }

        }

        // Content scrollbar
        @if $type == secondary {

            &::-webkit-scrollbar {
                @include size( $span--xs );
            }

            &::-webkit-scrollbar-thumb {
                background-color:       $color--secondary--3;
            }

        }

    }

    .scrollbar,
    %scrollbar {

        // .scrollbar--primary
        // %scrollbar--primary
        &--primary {
            @include scrollbar( primary );
        }

        // .scrollbar--secondary
        // %scrollbar--secondary
        &--secondary {
            @include scrollbar( secondary );
        }

    }