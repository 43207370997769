//  --------------------------------------------------------------------------------
//  _label.scss
//  --------------------------------------------------------------------------------

//  Label
//   ├─ Default (grey)
//   ├─ Primary (blue)
//   ├─ Success (green)
//   ├─ Info (aqua)
//   ├─ Warning (amber)
//   └─ Danger (red)





//  --------------------------------------------------------------------------------
//  Label
//  --------------------------------------------------------------------------------

    .label {
        border:                 1px solid;
        border-radius:          0;//bso
        color:                  $text-color--secondary;
        font-size:              $font-size--small;
        font-weight:            $font-weight--medium;
        line-height:            100%;
        padding:                $span--xs;
        text-transform:         capitalize;
    }


//  Default (grey) -----------------------------------------------------------------

    .label-default {
        background-color:       hsb( 215, 5, 90 );
        border-color:           hsb( 215, 5, 85 );
    }


//  Primary (blue) -----------------------------------------------------------------

    .label-primary {
        background-color:       hsb( 210, 20, 98 );
        border-color:           hsb( 210, 20, 92 );
    }


//  Success (green) ----------------------------------------------------------------

    .label-success {
        background-color:       hsb( 135, 20, 95 );
        border-color:           hsb( 135, 20, 88 );
    }


//  Info (aqua) --------------------------------------------------------------------

    .label-info {
        background-color:       hsb( 180, 20, 95 );
        border-color:           hsb( 180, 20, 88 );
    }


//  Warning (amber) ----------------------------------------------------------------

    .label-warning {
        background-color:       hsb( 25, 20, 100 );
        border-color:           hsb( 25, 20, 92 );
    }


//  Danger (red) -------------------------------------------------------------------

    .label-danger {
        background-color:       hsb( 8, 20, 100 );
        border-color:           hsb( 8, 20, 90 );
    }