//  --------------------------------------------------------------------------------
//  _table.scss
//  --------------------------------------------------------------------------------

//  Ui Grid
//   ├─ Containers
//   ├─ Rows
//   ├─ Cells
//   ├─ Controls
//   └─ Dropmenus
//  Table
//   ├─ Cells
//   ├─ Captions
//   ├─ Headers
//   └─ Footers





//  --------------------------------------------------------------------------------
//  UI Grid
//  --------------------------------------------------------------------------------

//  Containers ---------------------------------------------------------------------

    // Main container of UI grid.
    // Adding 'ui-grid-dynamic' will make the maximum
    // height of the ui grid span up to the viewport height.
    .ui-grid {
        border:                 0;
        clear:                  both;
        height:                 auto !important;
        padding:                0;
        padding-bottom:         45px;
        padding-top:            35px;
        width:                  auto !important;
    }

    .ui-grid-canvas {
        margin-right:           (-$span--m);
        height:                 auto !important;
        width:                  auto !important;
    }

    .ui-grid-render-container {
        z-index:                0;

        &::after {
            @include absolute( bottom );
            border-bottom:          1px solid $color--secondary--1;
            content:                '';
            display:                block;
            margin-left:            (-$span--m);
            width:                  calc( 100% + 30px );

            .ui-grid-pinned-container & {
                display:                none;
            }

        }

    }

    .ui-grid-pinned-container {
        pointer-events:         none;
        z-index:                1;

        &,
        .ui-grid-render-container {
            width:                  100% !important;
        }

    }

    .ui-grid-pinned-container + .ui-grid-render-container {
        margin:                 0 !important;

        .ui-grid-row {
            padding-left:           45px;
        }

        .ui-grid-header {
            padding-left:           50px;
        }

    }

    .ui-grid-viewport {
        @include scrollbar( secondary );
        height:                 auto !important;
        margin:                 0 (-$span--m);
        max-height:             300px;
        min-height:             50px;
        overflow-x:             hidden !important;
        overflow-y:             auto !important;
        padding:                0 $span--m;
        position:               relative;
        width:                  auto !important;

        .ui-grid-dynamic & {
            max-height:             calc( 100vh - 300px );
        }

    }

    // Remove unsightly outline.
    .ui-grid-focuser:focus {
        box-shadow:             none;
    }


//  Rows ---------------------------------------------------------------------------

//  Header

    .ui-grid-top-panel {
        background:             transparent !important;
    }

    .ui-grid-header {
        border:                 0 !important;
        border-bottom:          1px solid $color--secondary--1 !important;
        margin:                 0 (-$span--m);
        padding:                0 $span--m;
    }

    // Sort indicators
    .ui-grid-icon-up-dir,
    .ui-grid-icon-down-dir,
    .ui-grid-sort-priority-number {
        color:                  $text-color--placeholder;
    }

//  General

    .ui-grid-row {
        background-color:       transparent;
        border-bottom:          1px solid $color--secondary--1;
        height:                 auto !important;
        max-height:             50px;
        margin:                 0 (-$span--m);
        padding:                0 $span--s;
        position:               relative;
        transition:             background-color 0.2s ease-in-out;

        > div {
            @include clearfix;
            display:                block;
            max-height:             50px;
            overflow:               hidden;
            position:               relative;
        }

        .ui-grid-pinned-container & {
            background-color:       transparent !important;
        }

        &:hover {
            background-color:       $color--secondary--1;
        }

    }

//  Multiple item selection

    .ui-grid-selection-row-header-buttons.ui-grid-row-selected,
    .ui-grid-selection-row-header-buttons.ui-grid-all-selected {
        @extend %qi--checkmark--sml;
        color:                  $text-color--secondary;
    }

    .ui-grid-pinned-container .ui-grid-cell:last-child {
        border:                 0 !important;
    }


//  Cells --------------------------------------------------------------------------

    .ui-grid-cell {
        background-color:       transparent !important;
        border:                 0;
        cursor:                 pointer !important;
        height:                 50px !important;
        line-height:            150%;
        overflow-x:             hidden;
        padding:                $span--m $span--xs;
        position:               relative;
        text-overflow:          ellipsis;
        white-space:            nowrap;

        > img {
            @include absolute( top left bottom right );
            margin:                 auto;
            max-height:             40px;
            max-width:              40px;
            padding:                0 $span--xs;
        }

        .btn {
            margin:                 (-$span--s) 0;
        }

    }

    .ui-grid-header-cell {
        border:                 0 !important;
        border-right:           $span--s solid transparent !important;
        font-weight:            $font-weight--bold;
        // height:                 35px;
        line-height:            35px;

        .ui-grid-cell-contents {
            border-right:           0 solid transparent;
            transition:             border-width 0.2s ease-in-out 0.2s;
        }

        &:hover .ui-grid-cell-contents {
            border-right-width:     35px;
            transition-delay:       0s;

            // Don't apply border for the header item
            // with the select all checkbox or the
            // checkbox will be cut off.
            .ui-grid-pinned-container & {
                border:                 0;
            }

        }

        .ui-grid-selection-row-header-buttons {
            margin-top:             5px;
        }

    }

    .ui-grid-cell-contents {
        padding:                0 !important;
        pointer-events:         all;
    }

    .ui-grid-cell a::after {
        @include absolute( top left );
        @include size( 100% );
        content: '';
    }


//  Controls -----------------------------------------------------------------------

    .ui-grid-pager-panel {
        padding:                5px !important;
        position:               static !important;
    }

    .ui-grid-pager-control {
        margin:                 0;
        margin-right:           $span--l;
    }

    // Container for the top right controls.
    .ui-grid-menu-button {
        background:             transparent;
        border:                 0;
        top:                    -35px;
        width:                  auto;

        .ui-grid-icon-container {
            margin:                 0;
        }

        .ui-grid-icon-cancel::before { content: '\2717' }
        .ui-grid-icon-ok::before     { content: '\e649' }

    }


//  Buttons

    // All buttons
    .ui-grid-icon-menu,
    .ui-grid-pager-control button {
        @include qi;
        @include size( 35px );
        background-color:       transparent !important;
        border:                 0;
        border-radius:          0;
        color:                  $text-color--placeholder;
        display:                inline-block;
        opacity:                1 !important;
        position:               relative;
        transition:             color 0.2s ease-in-out;

        &::before {
            @include absolute( top left );
            @include size( 35px );
            line-height:            35px !important;
            margin:                 0;
        }

        &:focus,
        &:hover {
            color:                  $text-color--secondary;
            outline:                0;
        }

        &:disabled {
            color:                  $text-color--placeholder;
            cursor:                 not-allowed;
        }

    }

    .ui-grid-column-menu-button {
        @include absolute( top right );
        @include is-hidden;
        @include size( 35px );
        background-color:       white;
        color:                  $text-color--placeholder;
        margin:                 0;

        .ui-grid-icon-angle-down {
            @include qi;
            @include size( 35px );
            display:                block;

            &::before {
                @include size( 35px );
                content:                '\e648';
                line-height:            35px;
                margin:                 0 !important;
            }

        }

        .ui-grid-header-cell:hover &,
        &:focus {
            @include is-visible;
        }

        &:hover {
            color:              $text-color--secondary;
        }

    }

    // Filter buttons
    .ui-grid-icon-menu {

        &::before {
            content:                '\e634';
            line-height:            35px;
        }

    }

    .ui-grid-column-menu-button-last-col {
        margin-right:           45px;
    }

    // Pagiation controls
    .ui-grid-pager-control button > div {
        display:                none;
    }

    .ui-grid-pager-max-pages-number {
        vertical-align:         middle !important;
    }

    .ui-grid-pager-first    { @extend %qi--arrow--first }
    .ui-grid-pager-previous { @extend %qi--arrow--left }
    .ui-grid-pager-next     { @extend %qi--arrow--right }
    .ui-grid-pager-last     { @extend %qi--arrow--last }

//  Form inputs

    .ui-grid-pager-control input,
    .ui-grid-pager-row-count-picker > select {
        border:                 1px solid $color--secondary--1;
        border-radius:          0;
        box-shadow:             none !important;
        height:                 35px !important;
        width:                  75px !important;

        &:focus {
            background-color:       $color--secondary--1 !important;
            border-color:           $color--secondary--1 !important;
            border-bottom-color:    shade( $color--secondary--2, 3.25% ) !important;
        }

    }

    .ui-grid-pager-row-count-picker > select {
        appearance:             none;
        // @@@@@ temp prefixes until autoprefix is added to gulp
        -webkit-appearance:     none;
        -moz-appearance:        none;
        background-image:       linear-gradient( 45deg, transparent 50%, #aaa 50% ),
                                linear-gradient( 135deg, #aaa 50%, transparent 50% );
        background-position:    calc( 100% - 18px ) 50%,
                                calc( 100% - 14px ) 50%;
        background-repeat:      no-repeat;
        background-size:        4px 4px,
                                4px 4px;
        padding-right:          35px;
    }

    .ui-grid-filter-button .ui-grid-icon-cancel {
        @include qi;
        color:                  $text-color--placeholder;
        opacity:                1 !important;
        position:               static !important;
        transition:             color 0.2s ease-in-out;

        &::before {
            @include absolute( top right );
            @include size( 35px );
            content:                '\2717';
            line-height:            35px;
            margin:                 0;
        }

        &:hover {
            color:                  $text-color--secondary;
        }

    }

    .ui-grid-filter-container {
        padding:                0;
        padding-bottom:         $span--s;
    }

    .ui-grid-filter-input {
        border:                 1px solid $color--secondary--1 !important;
        font-weight:            $font-weight--normal;
        padding:                0 $span--s !important;
        transition:             background-color 0.2s ease-in-out,
                                border 0.2s ease-in-out;

        &:focus {
            background-color:       $color--secondary--1;
            border-bottom-color:    $color--secondary--2 !important;
        }

    }

//  Checkboxes for multiple item selection

    .ui-grid-selection-row-header-buttons {
        @include qi;
        @include size( 20px );
        @extend %qi--xmark--sml;
        background-color:       $color--secondary--2;
        color:                  $color--secondary--3;
        display:                block;
        opacity:                1;
        pointer-events:         all;
        position:               relative;

        &::before {
            @include absolute( top left );
            @include size( 20px );
            line-height:            20px;
            margin:                 0;
            text-indent:            -2px;
        }

    }


//  Dropmenus ----------------------------------------------------------------------

    .ui-grid-menu {
        background-color:       $color--secondary--1;
        max-width:              200px;
        min-width:              150px;
        padding:                0;
        top:                    100%;
    }

    .ui-grid-menu-mid {
        @include scrollbar( secondary );
        border:                 0 !important;
        max-height:             $panel-size !important;
        overflow-x:             hidden !important;
        overflow-y:             auto !important;
    }

    .ui-grid-menu-inner {
        background-color:       transparent !important;
        border:                 0 !important;
        box-shadow:             none !important;
    }

    .ui-grid-menu-close-button {
        display:                none !important;
    }

    // This is the 'Column:' menu item in the main ui-grid
    // table. We'll hide it and instead put a divider in
    // it's place in the dropdown menu.
    .ui-grid-menu-button #menuitem-2 {
        border-bottom:          1px solid $color--secondary--2;

        > button {
            display:                none;
        }

    }

    .ui-grid-menu-item {
        border:                 0 !important;
        box-shadow:             none !important;
        font-size:              $font-size--body;
        height:                 35px !important;
        padding:                $span--s !important;
        position:               relative;

        &:hover {
            background-color:       $color--secondary--2 !important;
            box-shadow:             none !important;
        }

        &.ui-grid-menu-item-active {
            background-color:       $color--secondary--3 !important;
            box-shadow:             none !important;
        }

        > i {
            @include absolute( top right );
            @include qi;
            color:                  #aaa;

            &::before {
                content:                '';
                line-height:            35px;
            }

        }

    }

    // Remove the 1px margin around this element
    // that is causing the dropdown menu to have
    // an unecessary scrollbar.
    .ui-grid-sr-only {
        margin:                 0;
    }





//  --------------------------------------------------------------------------------
//  Table
//  --------------------------------------------------------------------------------

    // table.table
    .table {
        color:                  $text-color--secondary;
        left:                   -$span--m;
        max-width:              initial !important;//bso
        margin-bottom:          0 !important;//bso
        position:               relative;
        width:                  100%;
    }

    .table > tbody {
        border-top:             1px solid $color--secondary--1;

        + tbody {
            border-top:             3px solid $color--secondary--1;
        }

        // Table rows
        > tr {
            border-bottom:          1px solid $color--secondary--1;
            transition:             background-color 0.2s ease-in-out;

            &:hover {
                background-color:       $color--secondary--1;
            }

        }

    }


//  Cells --------------------------------------------------------------------------

    // General table cells
    .table {

        td,
        th {
            border:                 0 !important;//bso
            height:                 100%;
            padding:                $span--m $span--xs !important;//!!!!!

            &:first-of-type {
                padding-left:           $span--m !important;
            }

            &:last-of-type {
                padding-right:          $span--m !important;
            }

        }

    }

    // Table body cells
    .table > tbody {

        td,
        th {
            vertical-align:         top;
        }

    }

    // Buttons in tables
    .table td .btn-group {
        display:                flex;
        margin:                 (-$span--xs) 0;
    }

    table tr:hover {

        button {
            color:                  #aaa;
        }

        a {
            border-bottom-color:    #aaa;
        }

    }


//  Captions -----------------------------------------------------------------------

    .table > caption {
        margin-bottom:          $span--m;
        padding:                0 $span--m;
        text-align:             left;

        > .button-group {
            margin-bottom:          $span--m;
        }

    }


//  Headers ------------------------------------------------------------------------

    // Applies to both row and column table headings
    .table th {
        font-weight:            $font-weight--bold;
        white-space:            nowrap;
    }

    // Column (vertical) table headings
    .table > tbody th:nth-child(1) {
        width:                  33%;
    }

    // Row (horizontal) table headings
    .table > thead th {
        height:                 35px;
        padding-left:           $span--xs !important;//!!!!!
        padding-right:          $span--xs !important;//!!!!!
        vertical-align:         middle !important;//!!!!!
    }


//  Footers ------------------------------------------------------------------------

    // If tbody exists, put some padding between
    // it and the table footer.
    .table > tfoot + tbody::after {
        content:                '';
        display:                block;
        height:                 $span--s;
    }