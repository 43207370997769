//  --------------------------------------------------------------------------------
//  _sidebar.scss
//  --------------------------------------------------------------------------------

//  Sidebar
//   ├─ Main
//   ├─ Calendar
//   └─ Selection





//  --------------------------------------------------------------------------------
//  Sidebar
//  --------------------------------------------------------------------------------

    .sidebar {
        background-color:       $color--primary--3;
        color:                  $text-color--primary;
        transition:             border 0.2s ease-in-out,
                                transform 0.2s ease-in-out;
        z-index:                $z-index--panel;
    }


//  Main ---------------------------------------------------------------------------

    .sidebar--main {
        @include scrollbar( primary );
        @include size( $panel-size, 100% );
        border-right:           3px solid $color--primary--4;
        overflow:               hidden;

        &:hover {
            // Showing a vertical scrollbar if necessary.
            overflow-x:             hidden;
            overflow-y:             auto;
            // Fix for blink/webkit issue where on hover out
            // the space that the scrollbar previously took
            // will persist. Changing the positioning will force
            // a reflow and repaint.
            position:               relative;
        }

        @media ( max-width: 1000px ) {
            width:                  1000px * (2/12);
        }

    }


//  Calendar -----------------------------------------------------------------------

    .sidebar--calendar {
        border-left:            3px solid $color--primary--4;
        z-index:                $z-index--panel;

        .sidebar--calendar--full & {
            border-left:            0;
        }

    }


//  Selection ----------------------------------------------------------------------

    // .sidebar.sidebar--image-viewer
    .sidebar--image-viewer {
        border-bottom:          250px solid transparent;
    }