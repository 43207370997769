//  --------------------------------------------------------------------------------
//  _form.scss
//  --------------------------------------------------------------------------------

//  Form input types
//   ├─ Float label
//   └─ Standard
//  Specific input types
//   ├─ Checkbox / radio button
//   ├─ Select
//   ├─ Textarea
//   └─ File upload





//  --------------------------------------------------------------------------------
//  Form input types
//  --------------------------------------------------------------------------------

//  Float label --------------------------------------------------------------------

    // Container
    .form-group {
        position:               relative;
        left:                   -($span--m);
        // Float label inputs should stack on top of each other.
        // Prevent display of double borders.
        margin-bottom:          -1px;
    }

    .form-control {
        @include placeholder( transparent );
        @include size( 100%, 50px );
        border:                 solid $color--secondary--1;
        border-radius:          0;//bso
        border-width:           1px 0;
        box-shadow:             none;//bso
        font-size:              $font-size--body;//bso
        padding:                0 $span--m;
        transition:             background-color 0.2s ease-in-out,
                                border 0.2s ease-in-out,
                                height 0.2s ease-in-out;

        &:focus {
            @include placeholder;
            background-color:       $color--secondary--1 !important;
            border-color:           $color--secondary--1;//bso
            border-bottom-color:    shade( $color--secondary--2, 3.25% );
            box-shadow:             none;//bso
            position:               relative;
            z-index:                1;
        }

        .table tr:hover & {
            border-color:           $color--secondary--2;
        }

    }

    .form-group > label {
        @include absolute( top left );
        color:                  $text-color--placeholder;
        height:                 50px;
        line-height:            50px;
        padding-left:           $span--m;
        pointer-events:         none;
    }

    .form-group.active {

        // (.form-group.active .form-control)
        .form-control {
            padding-top:            18px;
        }

        // (.form-group.active > label)
        > label {
            @include fade-in;
            font-size:              $font-size--small;
            transform:              translate3d( 0, -8px, 0 );
            transition:             opacity 0.2s ease-in-out,
                                    transform 0.2s ease-in-out;
            z-index:                2;
        }

    }



//  Standard -----------------------------------------------------------------------

//  - Inline form input
//  - Horizontal form input

    .form-horizontal,
    .form-inline {

        .form-group {
            margin-bottom:          $span--s;

            .table & {
                margin:                 (-$span--s) 0;
            }

        }

        .form-control {
            @include placeholder( $color--secondary--3 );
            background-color:       transparent;//bso
            border-width:           1px;

            .modal &,
            .sidebar--products &,
            .sidebar & {
                border-color:           $color--primary--4;
                color:                  $text-color--primary;

                &:focus {
                    background-color:       $color--primary--4 !important;
                }

            }

        }

        // Note: deliberately using this selector rather than
        // the much more direct 'control-label' class. This
        // selector also will target inline form groups.
        .form-group > label {
            color:                  $text-color--secondary;
            line-height:            35px;
            overflow:               hidden;
            pointer-events:         all;
            // Resets the absolute position that was
            // applied for the float label.
            position:               relative;
            text-align:             left;
            vertical-align:         middle;
            white-space:            nowrap;

            .modal &,
            .sidebar--products &,
            .sidebar & {
                color:                  inherit;
            }

        }

        .form-control,
        .form-group > label {
            height:                 35px;
            padding:                0 $span--s;
        }

    }

    .form-inline .form-group {
        left:                   -($span--s);
    }

    .form-horizontal .form-group {
        left:                   0;
    }





//  --------------------------------------------------------------------------------
//  Specific input types
//  --------------------------------------------------------------------------------

//  Checkbox / radio button --------------------------------------------------------

//  There is no need for an 'id' attribute on the input and
//  a corresponding 'for' attribute on the label.

//  To create the functionality where the label can also be
//  clicked to toggle the checkbox - the input should be
//  placed inside the label tag.

//  Checkbox markup structure
//      <div class="checkbox">
//          <label>
//              <input type="checkbox">
//          </label>
//      </div>

//  Radio button markup structure:
//      <div class="radio">
//          <label>
//              <input name="" type="radio">
//          </label>
//      </div>

    // Checkbox/radio input container.
    .checkbox,
    .radio {
        // Floated to the left and then cleared to get a faux
        // block element while the width remains flexible.
        clear:                  both;
        float:                  left;
        height:                 20px;
        position:               relative;

        .form-inline & {
            // Don't float left when in an inline form.
            float:                  none;
            margin-bottom:          $span--s;
            padding:                0;//bso
        }

        .form-horizontal & {
            margin:                 $span--s 0;
            min-height:             20px;//bso
            padding:                0;//bso
        }

        & + & {
            margin-top:             (-$span--xs);
        }

    }

    // Label text.
    .checkbox,
    .radio {

        > label {
            color:                  $text-color--secondary;
            font-weight:            $font-weight--normal;//bso
            height:                 20px;
            line-height:            20px;
            padding-left:           20px + $span--xs !important;
            padding-right:          $span--s;
            user-select:            none;

            .modal &,
            .sidebar--products &,
            .sidebar & {
                color:                  $text-color--primary;
            }

        }

    }

    .checkbox input,
    .radio input {
        @include absolute( top 2px left 2px );
        cursor:                 pointer;
        margin-left:            0 !important;//bso
        margin-top:             0 !important;//bso
        outline:                0 !important;
        position:               absolute !important;//bso
    }

    // Checkbox inital state.
    .checkbox input::before {
        @include absolute( top -2px left -2px );
        @include size( 20px );
        background-color:       $color--secondary--1;
        color:                  $color--secondary--2;
        content:                '\e64a';
        display:                block;
        font:                   24px 'Qantifi Icons';
        line-height:            20px;
        text-indent:            -2px;
        transition:             background-color 0.2s ease-in-out,
                                color 0.2s ease-in-out;

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       $color--primary--4;
            color:                  $color--primary--4;
        }

    }

    // Radio button initial state.
    .radio input {

        &::after,
        &::before {
            content:                '';
            display:                block;
            transition:             background-color 0.2s ease-in-out,
                                    color 0.2s ease-in-out;
        }

        &::before {
            @include absolute( top -2px left -2px );
            @include size( 20px );
            background-color:       $color--secondary--1;
            border-radius:          10px;

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       $color--primary--4;
            }

        }

        // Inner dot inside the radio input button.
        &::after {
            @include absolute( top 3px left 3px );
            @include size( 10px );
            background-color:       $color--secondary--2;
            border-radius:          5px;

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       $color--primary--4;
            }

        }

    }

    // Checkbox focused/hovered state.
    .checkbox input:focus::before,
    .checkbox input:hover::before {
        background-color:       $color--secondary--2;
        color:                  $color--secondary--3;

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       $color--primary--4;
            color:                  $color--primary--3;
        }

    }

    // Radio focused/hovered state
    .radio input:focus,
    .radio input:hover {

        // Inner dot in radio button
        &::after {
            background-color:       $color--secondary--3;

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       $color--primary--3;
            }

        }

        // (Custom) radio button
        &::before {
            background-color:       $color--secondary--2;

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       $color--primary--4;
            }

        }

    }

    // Checkbox checked state.
    .checkbox input:checked::before {
        color:                  $text-color--secondary;
        content:                '\e649';

        .modal &,
        .sidebar--products &,
        .sidebar & {
            color:                  $text-color--primary;
        }

    }

    // Radio checked state
    .radio input:checked::after {
        background-color:       $text-color--secondary;

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       $text-color--primary;
        }

    }

    // Specific cases
    .form-horizontal {

        // When checkbox/radio is placed in a .form-horizontal
        // container and is not incased in a form-group container.
        // Force width to span 100% to act as .form-group.
        // (for example: .form-horizontal > .form-group >
        // .col-sm-offset-3.col-sm-9 > .checkbox)
        > .checkbox,
        > .radio {
            width:                  100%;
        }

        // Support for this structure:
        //
        // <label class="checkbox">
        //     <input type="checkbox">
        // </label>
        label.checkbox,
        label.radio {
            padding-left:           25px !important;

            > input {
                opacity:                1;
            }

        }

    }


//  Select -------------------------------------------------------------------------

//  Standard html selects
    select.form-control {
        appearance:             none;
        // @@@@@ temp prefixes until autoprefix is added to gulp
        -webkit-appearance:     none;
        -moz-appearance:        none;
        background-image:       linear-gradient( 45deg, transparent 50%, $text-color--placeholder 50% ),
                                linear-gradient( 135deg, $text-color--placeholder 50%, transparent 50% );
        background-position:    calc( 100% - 18px ) 50%,
                                calc( 100% - 14px ) 50%;
        background-repeat:      no-repeat;
        background-size:        4px 4px,
                                4px 4px;
        padding-right:          35px;

        // Non float label selects
        .form-horizontal &,
        .form-inline & {
            padding-right:          30px;
            background-position:    calc( 100% - 19px ) 50%,
                                    calc( 100% - 15px ) 50%;
        }

    }

//  AngularUI selects

    .ui-select-container.ui-select-container {
        z-index:                auto;
    }

    .ui-select-match {
        box-shadow:             none !important;
        outline:                none !important;
    }

    .ui-select-bootstrap .ui-select-toggle {
        background-color:       transparent !important;
        color:                  inherit !important;
        border:                 1px solid $color--secondary--1;
        font-weight:            $font-weight--normal;
        padding-right:          35px;
        text-shadow:            none;
        transition:             background-color 0.2s ease-in-out;

        &:active,
        &:hover,
        &:focus {
            border:                 1px solid $color--secondary--1;
            color:                  inherit;
        }

        .modal & {
            border:                 1px solid $color--primary--4;
        }

    }

    .ui-select-choices {
        @include scrollbar( secondary );
        background-color:       $color--secondary--1;
    }

    .ui-select-choices-row > a {
        background-color:       $color--secondary--1;
        border:                 0;
        color:                  $text-color--secondary !important;
        font-weight:            $font-weight--normal !important;
        height:                 35px;
        line-height:            35px !important;
        padding:                0 $span--s !important;

        &:hover,
        &:focus {
            background-color:       $color--secondary--2 !important;
        }

    }

    .ui-select-choices-row.active > a {
        background-color:       $color--secondary--3 !important;
    }

    .form-horizontal {

        .ui-select-container {
            width:                  100%;
        }

        .ui-select-match-text {
            line-height:            35px;
        }

        .ui-select-choices {
            margin-top:             0 !important;
            max-width:              100% !important;
        }

    }

    // Caret
    .ui-select-bootstrap .ui-select-toggle > .caret {
        @include absolute( top 50% right 15px );
        border-top-color:       $text-color--placeholder;
        border-width:           4px;
    }

//  Date picker

    .quickdate {
        margin-bottom:          -1px;
        margin-left:            (-$span--m);
        position:               relative;
        width:                  calc(100% + 15px);


        + label {
            color:                  $text-color--placeholder;
            display:                block;
            font-size:              $font-size--small;
            height:                 50px;
            margin-left:            (-$span--m);
            margin-top:             -50px;
            padding:                8px 15px;
            pointer-events:         none;
            width:                  calc( 100% + 15px );
        }

        &:hover + label {
            background-color:       $color--secondary--1;
        }

        .form-horizontal & {
            margin-left:            0;
            width:                  100%;
        }

    }

    .quickdate-button,
    .quickdate-popup {
        border-radius:          0;
        font-size:              13px;
        width:                  100%;
    }

    .quickdate-button {
        background:             transparent;
        border-style:           solid;
        border-width:           0 1px;
        height:                 50px;
        padding:                23px 15px 10px 15px;
        text-decoration:        none !important;

        &,
        &:focus,
        &:hover {
            border-color:           $color--secondary--1;
        }

        .form-horizontal & {
            border-width:           1px;
            height:                 35px;
            line-height:            35px;
            padding:                0 $span--s;
        }

    }

    .quickdate-popup {
        background-color:       $color--secondary--1;
        border:                 none;
        border-top:             1px solid shade( $color--secondary--1, 3.25% );
        box-shadow:             none;
        padding:                $span--s $span--m;
    }

    .quickdate-text-inputs {
        @include clearfix;
        margin:                 0 -5px;
        margin-bottom:          $span--s;
    }

    .quickdate-input-wrapper {
        float:                  left;
        padding:                0 5px;
        width:                  50%;

        > label {
            height:                 35px;
            line-height:            35px;
        }

    }

    .quickdate-date-input,
    .quickdate-time-input {
        border:                 1px solid $color--secondary--2;
        float:                  right;
        height:                 35px !important;
        padding:                0 $span--s !important;
        width:                  66% !important;
    }

    .quickdate-calendar-header {
        margin-bottom:          10px;
    }

    .quickdate-calendar {
        background-color:       transparent !important;

        &,
        th,
        td {
            border-color:           $color--secondary--2 !important;
            font-weight:            $font-weight--normal !important;
        }

        td {

            &:hover {
                background-color:       $color--secondary--2 !important;
            }

            &.selected {
                background-color:       $color--secondary--3 !important;
            }

        }

        .other-month {
            opacity:                0;
        }

        .is-today {
            font-weight:            $font-weight--bold !important;
            color:                  $text-color--secondary !important;
        }

    }

    .quickdate-close {
        display:                none;
    }

    .quickdate-popup-footer {
        margin:                 0;
        margin-top:             $span--s;
    }

    .quickdate-clear {
        background-color:       hsb( 215, 5, 75 );
        border-radius:          0;
        border:                 0 solid hsb( 215, 5, 60 );
        color:                  white;
        height:                 35px;
        line-height:            35px;
        padding:                0 $span--s;
        transition:             background-color 0.2s ease-in-out,
                                border-width 0.2s ease-in-out;

        &:focus,
        &:hover {
            border-bottom-width:    3px;
            background-color:       hsb( 215, 5, 65 );
            color:                  white;
        }

    }


//  Textarea -----------------------------------------------------------------------

    textarea.form-control {
        @include scrollbar( secondary );
        height:                 150px;
        line-height:            150%;
        resize:                 none;
        min-height:             150px;//bso
        padding-top:            15px;
    }

    .form-group.active textarea.form-control {
        padding-bottom:         $span--s;
        padding-top:            25px;
    }

    .form-horizontal,
    .form-inline {

        textarea.form-control {
            padding:                $span--s;
        }

    }


//  File upload --------------------------------------------------------------------

    // Hiding the default file upload field.
    .file-upload input {
        @include absolute( left -9999px );
    }

    // This will be styled to appear as a button
    // (.btn-default).
    .file-upload {
        background-color:       $color--secondary--1;
        cursor:                 pointer;
        display:                inline-block;
        height:                 35px;
        line-height:            35px;
        padding:                0 $span--s;
        transition:             background-color 0.2s ease-in-out;

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       $color--primary--2;
        }

        &.active,
        &:focus,
        &:hover {
            background-color:       $color--secondary--2;

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       $color--primary--1;
            }

        }

    }