//  --------------------------------------------------------------------------------
//  _breadcrumb.scss
//  --------------------------------------------------------------------------------

//  Breadcrumb
//   ├─ Items
//   └─ Seperator





//  --------------------------------------------------------------------------------
//  Breadcrumb
//  --------------------------------------------------------------------------------

//  <ol class="breadcrumb">
//      <li><a></a><li>
//  </ol>

    .breadcrumb {
        background-color:       transparent;//bso
        list-style:             none;
        margin:                 0;//bso
        padding:                0;//bso
    }


//  Items --------------------------------------------------------------------------

    .breadcrumb > li {
        display:                inline;
    }


//  Seperator ----------------------------------------------------------------------

    .breadcrumb > li + li {
        @include qi;
        @extend %qi--arrow--right--sml;

        &::before {
            color:                  $text-color--placeholder;
            padding:                0 $span--xs;
        }

    }