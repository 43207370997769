//  --------------------------------------------------------------------------------
//  _button.scss
//  --------------------------------------------------------------------------------

//  Button
//  Specialised types
//   ├─ Text
//   ├─ Icon
//   └─ Close
//  Sizes
//   ├─ Default
//   └─ Small
//  Button group
//  States
//   ├─ Default
//   ├─ Primary
//   ├─ Success
//   ├─ Info
//   ├─ Warning
//   └─ Danger





//  --------------------------------------------------------------------------------
//  Button
//  --------------------------------------------------------------------------------

    .btn {
        border:                 0;//bso
        border-radius:          0;//bso
        box-shadow:             none !important;//bso
        color:                  $text-color--secondary;
        display:                inline-block;
        float:                  left;
        font-size:              $font-size--body;//bso
        font-weight:            $font-weight--medium;
        height:                 35px;
        line-height:            35px;
        min-width:              35px;
        outline:                0 !important;
        overflow:               hidden;
        padding:                0 $span--s;
        position:               relative;
        text-shadow:            0 0 5px rgba( 255, 255, 255, 0.2 );
        transition:             background-color 0.2s ease-in-out,
                                border-width 0.2s ease-in-out,
                                color 0.2s ease-in-out,
                                line-height 0.2s ease-in-out;

        &.active,
        &:focus,
        &:hover {
            border-bottom-width:    3px;
            border-bottom-style:    solid;
            color:                  $text-color--secondary;
        }

        .modal &,
        .sidebar--products &,
        .sidebar & {
            border-width:           0;
            color:                  $text-color--primary;
            text-shadow:            0 0 5px hsb( 0, 0, 0, 0.2 );
        }

    }





//  --------------------------------------------------------------------------------
//  Specialised types
//  --------------------------------------------------------------------------------

//  Text ---------------------------------------------------------------------------

//  Buttons that appear to be like text (ie. without
//  background colors and borders). Usually paired
//  with an icon button.

    .btn-text {
        background-color:       transparent;
        color:                  $text-color--placeholder;

        &:focus,
        &:hover {
            border:                 0;
            color:                  $text-color--secondary;

            .modal &,
            .sidebar--products &,
            .sidebar & {
                color:                  $text-color--primary;
            }

        }

    }


//  Icons --------------------------------------------------------------------------

    .btn-icon {

        > .qi {
            // Offset excessive padding for icon buttons.
            margin:                 0 (-$span--xs);

            &::before {
                // Apply the same line height as the height
                // of buttons to vertically center.
                line-height:            35px;
                // Prevent psuedo element from interfering
                // with any button interactions.
                pointer-events:         none;
            }

        }

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       $color--primary--3;
            color:                  $text-color--primary;

            &:focus,
            &:hover {
                background-color:       $color--primary--2;
            }

            &.active,
            &:active {
                background-color:       $color--primary--1;
            }

        }

    }


//  Close --------------------------------------------------------------------------

    .close {
        opacity:                1 !important;//bso
        transition:             color 0.2s ease-in-out;
    }





//  --------------------------------------------------------------------------------
//  Sizes
//  --------------------------------------------------------------------------------

//  Default ------------------------------------------------------------------------

    // Uses same styles as .btn defined above.


//  Small --------------------------------------------------------------------------

    .btn-sm {

        &,
        > .qi::before {
            height:                 30px;
            line-height:            30px;
        }

    }





//  --------------------------------------------------------------------------------
//  Button group
//  --------------------------------------------------------------------------------

    .btn-group {
        @include clearfix;
        display:                inline-block;

        > .btn {
            z-index:                initial !important;//bso
        }

        + .btn-group {
            margin-left:            $span--l !important;
        }

    }

    .btn-group > .btn + .btn,
    .btn + .btn {
        margin-left:            $span--xs !important;//bso
    }

    .btn-group > .btn-icon + .btn-icon,
    .btn-icon + .btn-icon {
        margin-left:            0 !important;

        &.btn-default {
            margin-left:            $span--xs !important;//bso
        }

    }





//  --------------------------------------------------------------------------------
//  States
//  --------------------------------------------------------------------------------

//  Default ------------------------------------------------------------------------

    .btn-default {
        background-color:       hsb( 215, 5, 83 );

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       hsb( 215, 5, 43 );
            background-color:       $color--primary--2;
        }

        &.active,
        &:focus,
        &:hover {
            background-color:       hsb( 215, 5, 75 );
            border-bottom-color:    hsb( 215, 5, 70 );

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       hsb( 215, 10, 38 );
                background-color:       $color--primary--1;
            }

        }

    }


//  Primary ------------------------------------------------------------------------

    .btn-primary {
        background-color:       hsb( 210, 35, 100 );

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       hsb( 210, 50, 70 );
        }

        &.active,
        &:focus,
        &:hover {
            background-color:       hsb( 210, 60, 97 );
            border-bottom-color:    hsb( 210, 55, 87 );

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       hsb( 210, 70, 65 );
            }

        }

    }


//  Success ------------------------------------------------------------------------

    .btn-success {
        background-color:       hsb( 135, 30, 88 );

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       hsb( 135, 45, 70 );
        }

        &.active,
        &:focus,
        &:hover {
            background-color:       hsb( 135, 60, 80 );
            border-bottom-color:    hsb( 135, 55, 74 );

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       hsb( 135, 75, 65 );
            }

        }

    }


//  Info ---------------------------------------------------------------------------

    .btn-info {
        background-color:       hsb( 180, 33, 88 );

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       hsb( 180, 45, 70 );
        }

        &.active,
        &:focus,
        &:hover {
            background-color:       hsb( 180, 60, 78 );
            border-bottom-color:    hsb( 180, 55, 73 );

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       hsb( 180, 85, 65 );
            }

        }

    }


//  Warning ------------------------------------------------------------------------

    .btn-warning {
        background-color:       hsb( 25, 33, 100 );

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       hsb( 25, 45, 72 );
        }

        &.active,
        &:focus,
        &:hover {
            background-color:       hsb( 25, 60, 95 );
            border-bottom-color:    hsb( 25, 55, 86 );

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       hsb( 25, 70, 72 );
            }

        }

    }


//  Danger -------------------------------------------------------------------------

    .btn-danger {
        background-color:       hsb( 8, 32, 100 );

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       hsb( 8, 45, 72 );
        }

        &.active,
        &:focus,
        &:hover {
            background-color:       hsb( 8, 55, 98 );
            border-bottom-color:    hsb( 8, 50, 88 );

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       hsb( 8, 65, 72 );
            }

        }

    }
