//  --------------------------------------------------------------------------------
//  _content.scss
//  --------------------------------------------------------------------------------

//  Container
//   └─ Setup
//  Content sections
//   └─ Login





//  --------------------------------------------------------------------------------
//  Container
//  --------------------------------------------------------------------------------

//  Use case:
//  <div class="view">
//      <div class="content">
//          <div class="content__container--y">
//              <div class="content__container--x">
//                  <div ui-view="main">
//                      <!-- put page content here -->
//                  </div>
//              </div>
//          </div>
//      </div>
//  </div>


//  Setup --------------------------------------------------------------------------

    .view {
        // Contains the rest of the content that is not the
        // menu bar. Shifted down to clear the height of the
        // menu bar but being absolutely positioned means
        // you need to shave off the same amount off its height.
        @include absolute( top 50px );
        overflow:               hidden;
    }

    .content {
        @include absolute( top );
        @include size( 100% );
        border:                 0 solid transparent;
        color:                  $text-color--secondary;
        padding:                0;
        transition:             border 0.2s ease-in-out,
                                transform 0.2s ease-in-out;
    }

    // Y-axis based container.
    //
    // Must be inside .content element. This container is
    // necessary for displaying a scrollbar within the
    // content (rather than window).
    .content__container--y {
        @include scrollbar( secondary );
        @include size( 100% );
        overflow-y:             auto;
    }

    // X-axis based container.
    //
    // Must be inside .content__container--y element.
    // This container puts a maximum width limit
    // to the content area.
    .content__container--x {
        display:                block;
        max-width:              1200px;
        min-height:             100%;
        overflow:               hidden;
        padding:                $span--l;
        position:               relative;
    }

    // (.content__container--x > [ui-view])
    [ui-view='main'] {

        &.ng-enter {
            @include fade-in;
            animation-delay:        0.5s;
            opacity:                0;
            z-index:                -1;
        }

        &.ng-leave {
            @include absolute( top left );
            @include fade-out;
            @include size( 100% );
            overflow:               hidden;
            padding:                $span--l;
        }

    }





//  --------------------------------------------------------------------------------
//  Content sections
//  --------------------------------------------------------------------------------

    .content__section {
        @include clearfix;

        & + & {
            margin-top:             $span--xl;
        }

    }


//  Login --------------------------------------------------------------------------

    .content--login {
        color:                  $text-color--secondary;
        margin:                 auto;
        margin-top:             $span--xl;
        max-width:              $panel-size * 1.5;
        padding-bottom:         $span--xl;
        padding-left:           $span--m;
        padding-right:          $span--m;
        width:                  100vw;
    }