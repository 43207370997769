//  --------------------------------------------------------------------------------
//  _sidebar--products.scss
//  --------------------------------------------------------------------------------

//  Product selection panel
//   ├─ Horizontal view
//   ├─ Vertical view
//   └─ Expanded view
//  Product thumbnails
//   └─ Product icon
//  Image viewer





//  --------------------------------------------------------------------------------
//  Product selection panel
//  --------------------------------------------------------------------------------

    .sidebar--products {
        @include absolute( bottom right );
        background-color:       $color--primary--3;
        color:                  $text-color--primary;
        padding:                0 $span--m;
        transition:             transform 0.2s ease-in-out;
        z-index:                $z-index--panel;
    }

    .sidebar--products__toolbar {
        @include clearfix;
        border-bottom:          1px solid $color--primary--4;
        padding:                $span--s 0;
        position:               relative;
    }

    .sidebar--products__tags {
        @include absolute ( top $span--s );
        white-space:            nowrap;
    }

    .sidebar--products__options {
        float:                  right;
    }


//  Horizontal view ----------------------------------------------------------------

    .sidebar--products--horizontal {
        @include size( 100%, $panel-size );
        border-top:             3px solid $color--primary--4;

        .sidebar--products__tags {
            width:                  calc( 100% - 300px );
        }

        .sidebar--products__search {
            margin-right:           $span--m;
            width:                  150px;
        }

    }

    // Slide in and out animation
    // when panel display is toggled
    .sidebar--products--horizontal {
        transform:              translate3d( 0, 250px, 0 );

        &.sidebar--products--active {
            transform:              translate3d( 0, 0, 0 );
        }

    }


//  Vertical view ------------------------------------------------------------------

    .sidebar--products--vertical {
        @include size( $panel-size, 100% );
        border-left:            3px solid $color--primary--4;

        .sidebar--products__toolbar {
            height:                 95px;
        }

        .sidebar--products__toolbar > .dropdown > button {
            max-width:              76px;
            text-overflow:          ellipsis;
        }

        .sidebar--products__search {
            // 50px = 10(margin) + 35(buttons) + 5(margin)
            @include absolute( top 50px left );
            width:                  100%;
        }

    }

    // Slide in and out animation
    // when panel display is toggled
    .sidebar--products--vertical {
        transform:              translate3d( 250px, 0, 0 );

        &.sidebar--products--active {
            transform:              translate3d( 0, 0, 0 );
        }

    }


//  Expanded view ------------------------------------------------------------------

    .sidebar--products--full {
        border:                 0;
        height:                 100%;
        width:                  100%;

        .sidebar--products__toolbar {
            height:                 auto;
        }

        .sidebar--products__toolbar > .dropdown > button {
            max-width:              auto;
        }

        .sidebar--products__search {
            margin-right:           $span--m;
            position:               static;
            width:                  150px;
        }
    }





//  --------------------------------------------------------------------------------
//  Product thumbnails
//  --------------------------------------------------------------------------------

    .products-list {
        @include scrollbar( primary );

        .sidebar--products--horizontal > & {
            // Height calculated by panel height minus the offset.
            // 56px (toolbar) + 3px (top border).
            height:                 191px;
            // Negative margin to offset the side margins set on
            // the product selection panel container.
            margin:                 0 (-$span--m);
            overflow-x:             auto;
            overflow-y:             hidden;
            padding:                $span--m 0 $span--m $span--m;
            white-space:            nowrap;

            // Setting a right padding on the product list container
            // via setting it on the margin of the last product
            // in the list.
            .product:last-child {
                margin-right:           $span--m;
            }

        }

        .sidebar--products--vertical > & {
            height:                 calc( 100% - 95px );
            overflow-x:             hidden;
            overflow-y:             auto;
            padding:                $span--s $span--m 0 0;
            width:                  calc( 100% + 15px );
        }

        .sidebar--products--full > & {
            height:                 calc(100% - 56px);
            margin:                 0;
            overflow-x:             auto;
            overflow-y:             auto;
            padding:                $span--m 0;
            white-space:            normal;
            width:                  calc(100% + 15px);
        }

    }

    .sidebar--products--full .products-list > div > div > div {
        display:                flex;
        flex-wrap:              wrap;
    }

    // (figure.product)
    .product {
        cursor:                 pointer;
        display:                inline-block;
        margin-right:           $span--s;
        position:               relative;
        vertical-align:         top;
        width:                  125px;

        figcaption {
            margin:                 3px;
            white-space:            normal;
            word-wrap:              break-word;

            .sidebar--products--horizontal & {
                margin-bottom:          0;
                margin-top:             $span--xs;
            }

            .sidebar--products--vertical & {
                height:                 100%;
                margin:                 0;
                overflow:               hidden;
                position:               relative;
            }

            .sidebar--products--full & {
                height:                 38px;
                margin:                 $span--xs 3px 0;
            }

        }

        .sidebar--products--horizontal & {
            // Setting an explicit height to avoid scroll issue.
            // Button (35) + padding (2x10 and 2x15) + border (1).
            height:                 $panel-size - 86px;
            overflow-y:             hidden;
        }

        .sidebar--products--vertical & {
            @include size( 100%, 90px );
            margin-bottom:          $span--s;
        }

        .sidebar--products--full & {
            height:                 auto;
            margin-bottom:          $span--s;
            flex-basis:             calc(25% - 10px);
        }

    }

    // When filter is active, remove products that are
    // inactive (ie. not being used on any design layer)
    // but show products that are (active).
    .product-list--filter .product {
        display:                none;

        &.active {
            display:                inline-block;
        }

    }

    .product__image {
        background-color:       white;
        border:                 3px solid $color--primary--4;
        height:                 90px;
        transition:             border 0.2s ease-in-out;

        img {
            padding:                $span--s;
            // Prevent image from being dragged.
            pointer-events:         none;
        }

        .product.focus & {
            border-color:           $color--primary--1;
        }

        .sidebar--products--vertical & {
            float:                  left;
            margin-right:           $span--s;
            padding-bottom:         $span--s;
            width:                  90px;
        }

        .sidebar--products--full & {
            float:                  none;
            height:                 auto;
            padding-bottom:         66.66%;
            width:                  100%;
        }

    }


//  Product icon -------------------------------------------------------------------

    .product__icon {
        @include absolute( top left );
        @include size( 35px );
        background-color:       $color--primary--4;
        display:                none;
        transition:             background-color 0.2s ease-in-out,
                                height 0.2s ease-in-out 0.2s,
                                width 0.2s ease-in-out 0.2s;
        z-index:                1;

        &:hover {
            @include size( 50px );
        }

        // Product is currently used on the plan.
        // Show the product icon in the thumbnail
        .product.active & {
            display:                block;
        }

        // Product is currently selected by mouse or
        // keyboard. Darken background color of product
        // icon to match thumbnail image border.
        .product.focus & {
            background-color:       $color--primary--1;
        }

    }

    // Adjustments to the svg symbol displayed in the
    // product icon preview (fill and stroke colors).
    .product__icon {

        .symbol__background {
            fill:                   transparent;
        }

        .symbol__text--1,
        .symbol__text--2 {
            fill:                   $text-color--primary;
            stroke:                 transparent;
        }

        path,
        polygon {
            fill:                   $text-color--primary;
        }

    }





//  --------------------------------------------------------------------------------
//  Image viewer
//  --------------------------------------------------------------------------------

    .image-viewer--design {
        @include fixed( bottom left );
        @include size( $panel-size );
        background-color:       white;
        background-position:    center;
        background-repeat:      no-repeat;
        background-size:        contain;
        border-right:           1px solid $color--primary--4;
        border-top:             1px solid $color--primary--4;
        transition:             transform 0.2s ease-in-out;
        z-index:                $z-index--panel;

        img {
            padding:                $span--s;
        }

        > button {
            @include size( 100% );
            background-color:       rgba( 255, 255, 255, 0.25 ) !important;
            cursor:                 url(../img/cursors/window--maximise.svg) 12 12,
                                    pointer;
            opacity:                0;
            position:               relative;
            transition:             opacity 0.2s ease-in-out;
            z-index:                1;

            &:hover {
                opacity:                1;
            }

        }

    }