//  --------------------------------------------------------------------------------
//  _dropdown.scss
//  --------------------------------------------------------------------------------

//  Dropdown
//   ├─ Toggle
//   ├─ Menu
//   └─ Menu items
//  Tool palette dropdown






//  --------------------------------------------------------------------------------
//  Dropdown
//  --------------------------------------------------------------------------------

    .dropdown {
        cursor:                 pointer;
        display:                inline-block;
        float:                  left;
        position:               relative;
        z-index:                $z-index--dropdown;
    }

    // By default the caret will be for a drop'down' menu.
    // Meaning that the caret wil point downwards to
    // indicate that the menu opens downwards.
    .caret {
        @include size( 0 );
        display:                inline-block;
        border:                 3px solid transparent;
        border-top:             3px solid $text-color--secondary;
        vertical-align:         middle;

        // Drop'up' caret.
        // Caret points upwards which is simply achieved by
        // rotating the default drop'down' caret 180 degrees.
        .dropup & {
            transform:              rotate( 180deg );
            transform-origin:       center 25%;
            // Ugh, repeating what was declared in 'caret' again
            // below to override bootstrap's style.
            border:                 3px solid transparent;
            border-top:             3px solid $text-color--secondary;
        }

        .modal &,
        .sidebar--products &,
        .sidebar & {
            border-top-color:       $text-color--primary;
        }

    }


//  Toggle -------------------------------------------------------------------------

//  The button to activate the dropdown menu.

    .dropdown-toggle {
        display:                block;
        position:               relative;
    }


//  Menu ---------------------------------------------------------------------------

    .dropdown-menu {
        @include absolute( top 100% left );
        @include is-hidden;
        @include scrollbar( secondary );
        border:                 0;//bso
        border-radius:          0;//bso
        box-shadow:             none;//bso
        display:                block !important;//bso
        font-size:              $font-size--body;//bso
        margin:                 0;//bso
        max-height:             250px;
        max-width:              200px;
        min-width:              150px;
        overflow-x:             hidden;
        overflow-y:             auto;
        padding:                0;//bso
        position:               absolute !important;

        // When the dropdown is active show the dropdown menu.
        // - (.dropdown.open > dropdown-menu)
        // - (.dropdown.show > dropdown-menu)
        .open > &,
        .show > & {
            @include is-visible;
        }

        .modal &,
        .sidebar--products &,
        .sidebar & {
            @include scrollbar( primary );
        }

    }

    // Position the dropmenu to align to the right side
    // (.dropdown-menu.dropdown-menu-right)
    .dropdown-menu-right {
        right:                  0;
        left:                   auto;
    }


//  Menu items ---------------------------------------------------------------------

    // Interactive dropdown menu items
    // - (ul.dropdown-menu > li > a)
    // - (ul.dropdown-menu > li > button)
    .dropdown-menu > li {

        > a,
        > button,
        .dropdown-menu-collapse > li > button {
            @include size( 100%, 35px );
            background-color:       $color--secondary--1;
            border:                 0;
            border-left:            $span--s solid transparent;
            border-right:           $span--s solid transparent;
            color:                  inherit !important;
            display:                block;
            font-weight:            $font-weight--normal;
            line-height:            35px !important;
            overflow:               hidden;
            padding:                0 !important;//bso
            text-align:             left;
            transition:             background-color 0.2s ease-in-out;

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       $color--primary--2;
            }

        }

        > a:hover,
        > button:hover {
            background-color:       $color--secondary--2;
        }

        > a.active,
        > button.active {
            background-color:       $color--secondary--3;
        }

        > a:hover,
        > a.active,
        > button:hover,
        > button.active,
        .dropdown-menu-collapse > li > button.active  {

            .modal &,
            .sidebar--products &,
            .sidebar & {
                background-color:       $color--primary--1;
            }

        }

    }

    .dropdown-menu > .divider {
        background-color:       $color--secondary--2;
        height:                 1px;
        margin:                 0 !important;//bso

        .modal &,
        .sidebar--products &,
        .sidebar & {
            background-color:       $color--primary--4;
        }

    }





//  --------------------------------------------------------------------------------
//  Tool palette dropdown
//  --------------------------------------------------------------------------------

    .btn-group--tool-palette .dropdown {
        z-index:                initial;
    }

    .btn-group--tool-palette .dropdown-toggle {
        @include size( 10px );
        line-height:            10px;
        position:               absolute;
        right:                  0;
        top:                    24px;
    }

    .btn-group--tool-palette .dropdown-menu {
        border:                 1px solid #8b8b8b;
        margin-left:            -20px;
        top:                    34px;
        z-index:                1;
    }

    .btn-group--tool-palette .dropdown-menu-right {
        margin-right:           -10px;
    }