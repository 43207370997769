//  --------------------------------------------------------------------------------
//  _nav.scss
//  --------------------------------------------------------------------------------

//  Nav
//   └─ Stacked nav





//  --------------------------------------------------------------------------------
//  Nav
//  --------------------------------------------------------------------------------

    .nav {
        list-style:             none;
    }


//  Stacked nav --------------------------------------------------------------------

    // Container.
    // (ul.nav-stacked)
    .nav-stacked {

        // Offset the padding of the panel body
        .panel-body > & {
            margin:                 (-$span--s) (-$span--m);
        }

    }

    // Stacked nav items.
    .nav-stacked > li {
        border-bottom:          1px solid $color--primary--4;
        margin:                 0 !important;//bso

        &:last-of-type {
            border-bottom:          0;
        }

        > a {
            @include size( 100%, 35px );
            background-color:       $color--primary--3;
            border:                 0;
            border-radius:          0 !important;//bso
            display:                block;
            padding:                $span--s $span--m;
            transition:             background-color 0.2s ease-in-out;

            &.active,
            &:hover,
            &:focus {
                background-color:       $color--primary--2;
            }

        }

    }