//  --------------------------------------------------------------------------------
//  _design-object.scss
//  --------------------------------------------------------------------------------

//  Object setup
//   ├─ States
//  Product symbols
//   ├─ Snap points
//   └─ States
//  Shapes
//   ├─ Rectangle
//   └─ Ellipse
//  Spline
//  Annotation





//  --------------------------------------------------------------------------------
//  Object setup
//  --------------------------------------------------------------------------------

//  States -------------------------------------------------------------------------

    // When element can be interacted with,
    // display a red contour.
    @mixin contour--focused() {
        stroke:                 $color--contour--red !important;
        stroke-width:           1;
        transition:             stroke 0.1s ease-in-out;
        vector-effect:          non-scaling-stroke;
    }

    // When interactive element is currently
    // selected then make the contour blue.
    @mixin contour--active() {
        stroke:                 $color--contour--blue !important;
        stroke-width:           1;
        transition:             stroke 0.1s ease-in-out;
        vector-effect:          non-scaling-stroke;
    }


//  Contours -----------------------------------------------------------------------

//  Contour which appears around all canvas objects
//  while it is active.

    // Bounding box (rectangle) around the object
    .designerContourBorder {
        @include contour--active;
    }

    // Resize handles
    .designerResizer {
        fill:                   $color--contour--blue;
        // Using an invisble stroke to increase
        // the hoverable area.
        stroke:                 transparent;
        stroke-width:           8px;
    }

    // Resize handles cursors
    .designerContour > rect {

        &:nth-child(8n+6),
        &:nth-child(8n+7) {
            cursor:                 ns-resize;
        }

        &:nth-child(8n+8),
        &:nth-child(8n+9) {
            cursor:                 ew-resize;
        }

        &:nth-child(8n+4),
        &:nth-child(8n+3) {
            cursor:                 nesw-resize;
        }

        &:nth-child(8n+2),
        &:nth-child(8n+5) {
            cursor:                 nwse-resize;
        }

    }





//  --------------------------------------------------------------------------------
//  Product symbol
//  --------------------------------------------------------------------------------

    // Prevent symbol objects from being clipped.
    svg.object {
        overflow:               visible;
    }

    // Text placeholders
    .symbol__text--1,
    .symbol__text--2 {
        font-family:            DIN, "Helvetica Neue", "Arial", sans-serif;
        font-size:              5px;
        font-weight:            normal;
        paint-order:            stroke;
        stroke:                 white;
        stroke-width:           1.5;

				&.is-disabled {
					display: none;
				}
    }

    .symbol__background {
        fill:                   white;
    }


//  Contours -----------------------------------------------------------------------

//  Snap points

    // Adjust snap point positions
    .snap-point.nw {transform:translate( -2px ,  -2px )}
    .snap-point.n  {transform:translate( -1px ,  -2px )}
    .snap-point.ne {transform:translate(  0   ,  -2px )}
    .snap-point.w  {transform:translate( -2px ,  -1px )}
    .snap-point.c  {transform:translate( -1px ,  -1px )}
    .snap-point.e  {transform:translate(  0   ,  -1px )}
    .snap-point.sw {transform:translate( -2px ,   0   )}
    .snap-point.s  {transform:translate( -1px ,   0   )}
    .snap-point.se {transform:translate(  0   ,   0   )}


//  States -------------------------------------------------------------------------

//  Default

    // As the symbol has not been acted upon, there
    // are no contours shown.
    .outline__border {
        fill:                   hsb( 0, 0, 0, 0 );
        stroke:                 transparent;
        stroke-width:           1;
        transition:             stroke 0.1s ease-in-out;
        vector-effect:          non-scaling-stroke;
    }

    // Currently, product symbols have snap points defined.
    // However there are no utilised and for the time
    // being, they are not enabled.
    .snap-point {
        fill:                   transparent;
        transition:             fill 0.1s ease-in-out;
    }

//  Focus (hover) state

    .mode--replace,
    .mode--select,
    .mode--spline,
    .mode--stamp {

        .outline:hover > .outline__border {
            @include contour--focused;
        }

    }

    .mode--select,
    .mode--spline {

        .outline:hover > .snap-point {
            fill: $color--contour--red;
        }

    }

//  Active state
//
//  Which is currently not necessary, as the inserted
//  contour is doing this.

//  If a non-resizeable designerObject is selected
	.selected .outline > .outline__border{
        @include contour--active;
    }

    .selected .outline > .snap-point {
		fill: $color--contour--blue;
	}


//  --------------------------------------------------------------------------------
//  Shapes
//  --------------------------------------------------------------------------------

//  Shapes are only selectable when in select mode.


//  Rectangle ----------------------------------------------------------------------

    .mode--select .designerRectangle {

        // Focused (hover) state
        &:hover {
            @include contour--focused;
        }

        // Active state (i.e. when being dragged)
        &:active {
            @include contour--active;
        }

    }


//  Ellipse ------------------------------------------------------------------------

    //@@@@@ Need to create a red contour box around the ellipsis.
    .mode--select .designerEllipse {
        &:hover {}
        &:active {}
    }





//  --------------------------------------------------------------------------------
//  Splines
//  --------------------------------------------------------------------------------

//  Line ---------------------------------------------------------------------------

    .designerSpline {
        stroke :                black;
        fill :                  none;
        transition:             stroke 0.1s ease-in-out;
    }

    .mode--select .designerSpline {

        &:hover {
            stroke:                 $color--contour--red !important;
        }

        &:active,
        &.selected {
            stroke:                 $color--contour--blue !important;
        }

    }


//  Point --------------------------------------------------------------------------

    .designerPoint {
        fill :                  black;
        // Using an invisble stroke here to give a
        // more generous area to hover over.
        stroke:                 transparent;
        stroke-width:           10px;
        transition:             fill 0.1s ease-in-out;
    }

    .mode--select .designerPoint {

        &:hover {
            cursor:                 move;
            fill:                   $color--contour--red !important;
        }

        &:active,
        &.selected {
            fill:                   $color--contour--blue !important;
        }

    }





//  --------------------------------------------------------------------------------
//  Annotation
//  --------------------------------------------------------------------------------

    .designerAnnotation {
        paint-order: stroke;
        stroke: #fff;
        stroke-width: 2px;
    }

    .designerAnnotation.selected {
        outline: 1px solid $color--contour--blue;
    }





//  --------------------------------------------------------------------------------
//  Printout guidelines
//  --------------------------------------------------------------------------------

    .guidelines {
        pointer-events:         none;
    }
