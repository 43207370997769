//  --------------------------------------------------------------------------------
//  _shame.scss
//  --------------------------------------------------------------------------------





//  --------------------------------------------------------------------------------
//  What's this?
//  --------------------------------------------------------------------------------

//  This file is didicated to housing crappy,
//  nasty, hacky, quick-fix styles


//  appointment bookings ----------------------------------------------------------

    .appt-builder {
        color: #444 !important;
        position: absolute;
        height: calc(100% - 60px);
        width: calc(100% - 60px);
    }

    .appointment-calendar,
    .appointment-legend {
        height: 100%;
    }

    .appointment-calendar {
        padding-left: 15px;
        position: absolute;
        // right: 0;
        width: calc( 100% - 250px );
    }

    .appointment-legend {
        left: 0;
        line-height: 30px;
        max-height: 100%;
        overflow-y: auto;
        padding-right: 15px;
        position: absolute;
        width: 250px;
        @include scrollbar( secondary );

        .color-preview {
            pointer-events: auto;
        }
    }

    .appointment-legend__item {
        display: block;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
    }

    .input--checkbox--color {
        display: inline-block;

        > input {
            display: block;
            height: 0;
            margin: 0;
            width: 0;
            @include is-hidden;
        }

        > label {
            border: 1px solid transparent;
            cursor: pointer;
            display: inline-block;
            height: 16px;
            margin-right: 5px;
            position: relative;
            top: 3px;
            width: 30px;
        }

        > input:checked + label {
            background-color: transparent !important;
        }
    }

    .appointment-calendar__toolbar {
        height: 35px;
        position: absolute;
        width: calc( 100% - 15px );
    }


//  -------------------------------------------------------------------------------

    .color-preview {
        @include size( 30px, 15px );
        display: inline-block;
        vertical-align: middle;
    }


//  -------------------------------------------------------------------------------

    .nav-bottom {
        height:                 35px;

        > div {
            @include absolute( bottom 30px right 30px );
        }

    }


//  -------------------------------------------------------------------------------

    .signature-pad > canvas {
        background-color: white;
    }
