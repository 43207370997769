//  --------------------------------------------------------------------------------
//  _canvas.scss
//  --------------------------------------------------------------------------------

//  Canvas
//   └─ Grid guidelines
//  Modes
//   ├─ Stamp
//   ├─ Navigate (pan and zoom)
//   ├─ Zoom (to selected area)
//   ├─ Drawing
//   └─ Text





//  --------------------------------------------------------------------------------
//  Canvas
//  --------------------------------------------------------------------------------

//  Grid guidelines ----------------------------------------------------------------

    .grid__block {
        fill:                   none;
        // Using a transparent black to get the stoke color
        // to be #e6e6e6 rather than a solid color so that
        // it does not visibly cut through base plans.
        stroke:                 hsb( 0, 0, 0, 0.1 );
        stroke-width:           1;
    }





//  --------------------------------------------------------------------------------
//  Modes
//  --------------------------------------------------------------------------------

//  Prevent interaction and (focused) state on any
//  canvas objects when currently in the following modes:
    .mode--navigate,
    .mode--zoomto,
    .mode--text {

        .designerAnnotation,
        .designerEllipse,
        .designerPoint,
        .designerRectangle,
        .designerSpline,
        .symbol {
            pointer-events:         none;
        }

    }


//  Stamp --------------------------------------------------------------------------

//  Use copy cursor when hovered over product
//  symbols to indicate that they can be duplicated.

    .mode--stamp .symbol {
        cursor:                 copy;
    }


//  Navigate (pan and zoom) --------------------------------------------------------

//  When hovering over canvas - display a hand cursor to
//  indicate that the canvas can be clicked and dragged.
//  When active - use closed hand cursor.

    .mode--navigate .canvas {

        &:hover {
            cursor:                 move;
            cursor:                 grab;
            cursor:                 -moz-grab;
            cursor:                 -webkit-grab;
        }

        &:active {
            cursor:                 grabbing;
            cursor:                 -moz-grabbing;
            cursor:                 -webkit-grabbing;
        }

    }


//  Zoom (to selected area) --------------------------------------------------------

//  Note: this feature is currently on hold.
//  Leave cursor as default while hovering over canvas.
//  When active switch to a zoom-in cursor.

    .mode--zoomto .canvas:active {
        cursor:                 zoom-in;
    }


//  Drawing ------------------------------------------------------------------------

    .mode--spline,
    .mode--rectangle,
	.mode--ellipsis,
	.mode--measure,
	.mode--rescale {

        .canvas {

            &:hover,
            &:active {
                cursor:                 crosshair;
            }

        }

    }

//  Text ---------------------------------------------------------------------------

//  When hovering over the canvas in text mode.

    .mode--text .canvas {
        cursor:                 text;
    }