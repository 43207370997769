//  --------------------------------------------------------------------------------
//  _loader.scss
//  --------------------------------------------------------------------------------

//  Loader





//  --------------------------------------------------------------------------------
//  Loader
//  --------------------------------------------------------------------------------

//  To use:
//  - <div class="loader">Message</div>
//  - <div class="loader loader--block-ui">Message</div>

    // Message and the background to cover anything in
    // the content view.
    .loader {
        @include fixed( top 50px left );
        @include size( 100% );
        animation:              text-fade-in-out 2s infinite;
        background-color:       white;
        cursor:                 wait;
        font-size:              $font-size--head;
        padding:                $span--l;
        transition:             transform 0.2s ease-in-out;
        z-index:                $z-index--modal;

        .sidebar--main--active & {
            transform:              translate3d( $panel-size, 0, 0 );
        }

        // Loader is becoming active
        &.ng-hide-remove {
            @include fade-in( 1s );
        }

        // Loader is becoming inactive
        &.ng-hide-add {
            @include fade-out( 1s );
        }

    }

    // Animation for loader text. Text will almost fade out
    // and then fade back into it's original color.
    @keyframes text-fade-in-out {

        0% {
            color:                  $text-color--secondary;
        }

        50% {
            color:                  $text-color--placeholder;
        }

        100% {
            color:                  $text-color--secondary;
        }

    }

    // Adding 'loader--block-ui' will add a transparent
    // overlay that will prevent any mouse intereactions
    // with the application window.
    .loader--block-ui::after {
        background-color:       transparent;
        content:                '';
        display:                block;
        height:                 100%;
        left:                   -$panel-size;
        position:               absolute;
        top:                    -50px;
        // Lazily putting an excessive width on the overlay.
        // At most, the width needs to be at least 100vw
        // plus the panel size to cover everything.
        width:                  200%;
    }





//  Loader bar ---------------------------------------------------------------------

    @keyframes loader-bar-slide {
        from {
            transform:              scaleX( 0 );
        }
        to {
            transform:              scaleX( 0.975 );
        }
    }

    .loading-bar::before {
        background-color:       $color--secondary--1;
        content:                '';
        display:                block;
        height:                 3px;
        opacity:                0;
        transform-origin:       top left;
        transition:             opacity 0.25s ease-in-out;
        width:                  100%;
    }

    .loading-bar.is-loading::before {
        animation:              loader-bar-slide 50s ease-out;
        opacity:                1;
        transform:              scaleX( 0.975 );
    }
