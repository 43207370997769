//  --------------------------------------------------------------------------------
//  _alert.scss
//  --------------------------------------------------------------------------------

//  Alert
//   ├─ General
//   └─ Dismissible
//  States
//   ├─ Danger (red)
//   ├─ Warning (amber)
//   ├─ Success (green)
//   └─ Info (blue)





//  --------------------------------------------------------------------------------
//  Alert
//  --------------------------------------------------------------------------------

//  General ------------------------------------------------------------------------

    .alert {
        border-radius:          0;//bootstrap override
        border-style:           solid;
        border-width:           1px;
        color:                  $text-color--secondary;
        margin:                 0;
        max-width:              1200px;
        min-height:             50px;
        padding:                15px;
        position:               relative;
        z-index:                1;

        + .alert {
            margin-top:             $span--m;
        }

        .body-designer & {
            @include absolute( top $span--l left $span--l );
        }

    }


//  Dismissible --------------------------------------------------------------------

    // Alert xmark dismiss/close button.
    // Won't be used (visible) unless the alert has been
    // specified that it can be dismissible.
    .alert-dismissible > .close {
        @include relative( top -5px right );
        float:                  right;
        height:                 30px;
        opacity:                1;
        text-shadow:            none;
        transition:             color 0.2s ease-in-out;
    }





//  --------------------------------------------------------------------------------
//  States
//  --------------------------------------------------------------------------------

//  Danger (red) -------------------------------------------------------------------

    .alert-danger {
        background-color:       hsb( 8, 20, 100 );
        border-color:           hsb( 8, 20, 90 );
    }


//  Warning (amber) ----------------------------------------------------------------

    .alert-warning {
        background-color:       hsb( 25, 20, 100 );
        border-color:           hsb( 25, 20, 92 );
    }


//  Success (green) ----------------------------------------------------------------

    .alert-success {
        background-color:       hsb( 135, 20, 95 );
        border-color:           hsb( 135, 20, 88 );
    }


//  Info (blue) --------------------------------------------------------------------

    .alert-info {
        background-color:       hsb( 210, 20, 95 );
        border-color:           hsb( 210, 20, 88 );
    }