//  --------------------------------------------------------------------------------
//  _variable.scss
//  --------------------------------------------------------------------------------

//  Colors
//   ├─ Background
//   ├─ Design module
//   └─ Text
//  Typography
//   ├─ Weights
//   └─ Sizes
//  Layout
//   ├─ Span sizes
//   └─ Z-index





//  --------------------------------------------------------------------------------
//  Colors
//  --------------------------------------------------------------------------------

//  Background ---------------------------------------------------------------------

    $color--primary--1:     hsb( 0, 0, 35 );
    $color--primary--2:     tint( $color--primary--1, 10% );
    $color--primary--3:     tint( $color--primary--1, 20% );
    $color--primary--4:     tint( $color--primary--1, 30% );

    $color--secondary--1:   hsb( 0, 0, 94 );
    $color--secondary--2:   shade( $color--secondary--1, 7.5% );
    $color--secondary--3:   shade( $color--secondary--1, 15% );


//  Design module ------------------------------------------------------------------

    $color--contour--blue:  #4570c4;
    $color--contour--red:   #db4d4a;


//  Text ---------------------------------------------------------------------------

    $text-color--primary:   white;
    $text-color--secondary: #444;
    $text-color--placeholder: #aaa;





//  --------------------------------------------------------------------------------
//  Typography
//  --------------------------------------------------------------------------------

    $font-line-height:      150%;

//  Weights ------------------------------------------------------------------------

    $font-weight--normal:   400;
    $font-weight--medium:   500;
    $font-weight--bold:     600;


//  Sizes --------------------------------------------------------------------------

    $font-size--small:      11px;
    $font-size--body:       13px;
    $font-size--subhead:    20px;
    $font-size--head:       27px;





//  --------------------------------------------------------------------------------
//  Layout
//  --------------------------------------------------------------------------------

//  Lengths ------------------------------------------------------------------------

    // Span sizes
    //
    // Intended for use in margin and padding rules.
    $span--xs:              5px;
    $span--s:               10px;
    $span--m:               15px;
    $span--l:               30px;
    $span--xl:              60px;

    $panel-size:            250px;


//  Z-index ------------------------------------------------------------------------

    $z-index--tooltip:      50;
    $z-index--modal:        40;
    $z-index--header:       30;
    $z-index--dropdown:     20;
    $z-index--panel:        10;
