//  --------------------------------------------------------------------------------
//  _list-group.scss
//  --------------------------------------------------------------------------------

//  List group





//  --------------------------------------------------------------------------------
//  List group
//  --------------------------------------------------------------------------------

    .list-group {
        margin:                 0;
    }

    .list-group-item {
        @include size( 100%, 35px );
        background-color:       $color--primary--3 !important;
        border:                 0 !important;//bso
        border-bottom:          1px solid $color--primary--4 !important;
        border-radius:          0 !important;//bso
        color:                  $text-color--primary !important;
        display:                block;
        line-height:            35px;
        margin:                 0 !important;//bso
        padding:                0 $span--m;
        position:               relative;
        text-align:             left;
        transition:             background-color 0.2s ease-in-out;
        z-index:                auto !important;//bso

        &:last-of-type {
            border-bottom:          0 !important;
        }

        &.active,
        &:hover {
            background-color:       $color--primary--2 !important;
        }

    }

    .list-group-item {

        > a,
        > button {
            @include absolute( top left );
            @include size( 100%, 35px );
            padding:                0 $span--m;
        }

        > .badge {
            margin-top:             $span--s;
        }

        > .btn {
            background-color:       transparent !important;
            float:                  right;
            padding:                0;
            position:               relative;
            width:                  auto;
        }

    }

    .list-group-item > .qi::before,
		.list-group-item > .fa::before {
        display:                block;
        float:                  left;
        height:                 35px;
        line-height:            35px;
        margin-right:           $span--s;
    }

		.list-group-item > .fa {
			display: inline;

			&::before {
				font-size: 18px;
				width: 24px;
			}
			
		}
